@font-face {
	font-family: 'FSIndustrieNw-Regular';
	src: url('./font/fs/FSIndustrieNw-Regular.woff');
}

@font-face {
	font-family: 'FSIndustrieNw-Bold';
	src: url('./font/fs/FSIndustrieNw-Bold.woff');
}

body,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: FSIndustrieNw-Bold
}

body,
p {
	font-family: FSIndustrieNw-Regular
}

html {
	height: 100%;
	box-sizing: border-box;
}

.errors {
	color: #ca4d36 !important;
	font-size: 12px !important;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
	margin-bottom: 0;
}

span.si-errs {
	display: none !important;
}

@font-face {
	font-family: 'MyriadPro-Regular';
	src: url('./font/MyriadPro-Regular.otf');
}

@font-face {
	font-family: 'Roboto-Light_0';
	src: url('./font/Roboto-Light_0.ttf');
}

@font-face {
	font-family: 'Roboto-Bold_0';
	src: url('./font/Roboto-Bold_0.ttf');
}

@font-face {
	font-family: 'MyriadPro-Semibold';
	src: url('./font/MyriadPro-Semibold.otf');
}

@font-face {
	font-family: 'MyriadHebrew-Bold';
	src: url('./font/MyriadHebrew-Bold.otf');
}



a:focus,
a:hover {
	outline: inherit;
	text-decoration: none;
}

#header {
	border-bottom: 1px rgba(0, 0, 0, 0.07) solid;
	z-index: 99;
}

.rr-mobile-custom-icon {
	display: none;
}

.menu-countyy img {
	margin-right: 5px;
}

.top-info {
	background: #123c60;
	text-align: right;
	color: #fff;
	padding: 5px;
	font-size: 16px;
}

.sivpointtopoint thead th {
	font-size: 16px !important;
	text-align: center !important;
}

.sivpointtopoint td p {
	margin: 0;
	padding: 0px;
}

.sivpointtopoint td {
	padding: 12px;
	font-size: 14px;
	font-weight: 800;
}

.sivpointtopoint tr.point-to-point-even td {
	background-color: #a3a3a32b;
}

.ship_ppp,
.ship_pp {
	margin-right: 15px;
}

.header-menu-top {
	background: #fff;
}

.cookie-pops {
	width: 100%;
	margin: 0;
	border: none;
	max-width: 100%;
}

.pop-back-cookie {
	background: #f98744;
	padding: 0px;
}

.pop-parag {
	color: #fff;
	font-size: 14px;
	margin: 0;
}

.cookie-in-but {
	background: #f98744;
	border: 1px solid rgba(255, 255, 255, 0.73);
	color: #fff;
	padding: 5px 16px;
	font-weight: 600;
	box-shadow: 0px 0px 11px -1px rgba(255, 255, 255, 0.47843137254901963);
	font-size: 14px;
	margin-top: 5px;
}

a.cookie-in-but:hover {
	color: #fff;
}

.slick-slide {
	color: white;
	padding-bottom: 40px;
	font-size: 30px;
	text-align: center;
}

.slick-prev:before,
.slick-next:before {
	color: black;
}

.slick-dots {
	bottom: -30px;
}

.banner-aset.slick-slide {
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
	position: relative;
}

button.slick-prev.slick-arrow {
	left: -30px;
	z-index: 1;
}

button.slick-next.slick-arrow {
	right: -25px;
}

.slider ul.slick-dots {
	bottom: 0;
	right: 90px;
	width: auto;
	transform: translateY(-50%);
	top: 50%;
	height: auto;
	display: inline-table !important;
}


.slider ul.slick-dots li {
	display: block;
	height: 0px;
	width: 0px;
	color: #fff;
	font-weight: lighter;
	padding: 4px 3px;
	margin: 20px 0px;
}

.slick-prev:before,
.slick-next:before {
	font-family: 'slick';
	font-size: 25px !important;
	line-height: 1;
	opacity: .75;
	color: #103c5e !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slick-prev,
.slick-next {
	top: 39% !important;
}

.slick-dotted.slick-slider {
	margin-bottom: 0px !important;
}

.slider ul.slick-dots li.slick-active a {
	border: 2px solid #fff;
	border-radius: 50%;
	background: #f88240;
	color: #f88240;
}

.slider ul.slick-dots li a {
	padding: 0px 3px;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
	background: #fff;
	width: 15px;
	height: 15px;
	font-size: 10px;
	border-radius: 50%;
	display: block;
	box-shadow: 0px 0px 5px #070707;
}

.banner-fin {
	position: relative;
	margin-top: 65px;
}

nav.head-navbar {
	padding: 20px 0px 19px 0px;
	z-index: 1;
}

.head-navbar a img {
	width: 200px;
}

.navbar-default .navbar-nav>li.dropdown:hover>a,
.navbar-default .navbar-nav>li.dropdown:hover>a:hover,
.navbar-default .navbar-nav>li.dropdown:hover>a:focus {
	background-color: rgb(231, 231, 231);
	color: rgb(85, 85, 85);
}

li.dropdown:hover>.dropdown-menu {
	display: block;
}

a.dropdown-toggle.menu-heads {
	color: #001524;
	font-size: 16px;
	padding: 30px 11px;
	text-transform: capitalize;
	font-weight: 400;
	text-decoration: none;
	font-family: 'FSIndustrieNw-Regular';
}

sup {
	top: 0 !important;
	left: 10px !important;
}

.menu-heads.dropdown-toggle::after {
	display: none;
}

li.dropdown.dropdown-hovers ul li {
	margin: 0 0px;
	background: #7d7d7d00;
	transition: all .5s ease-out;
	border-bottom: none;
	position: relative;
}

select::-ms-expand {
	display: none;
}

.gsl_company_offices li a {
	font-size: 13px;
	color: #000;
	transition: all .5s ease-out;
	padding: 5px 10px !important;
	display: block;
	text-decoration: none;
}

li.dropdown.dropdown-hovers ul li a {
	font-size: 15px;
	color: #001524;
	transition: all .5s ease-out;
	padding: 10px 10px;
	display: block;
	text-decoration: none;
	font-weight: 400;
	font-family: 'FSIndustrieNw-Regular', sans-serif;

}

.head-menu-liss li a:hover img.head-arrow-h {
	left: 0;
	transition: all .5s ease-out;
	z-index: 1;
	opacity: 1;
}

img.head-arrow-h {
	left: -29px;
	width: 15px !important;
	margin-right: 5px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	transition: all .5s ease-in;
	z-index: -1;
	opacity: 0;
}

li.dropdown.dropdown-hovers ul.dropdown-menu.mmenu1 {
	background: transparent;
	text-align: left;
	margin: 0;
	padding: 0;
	border: none;
	min-width: 200px !important;
	height: 800px;
	box-shadow: none;
	left: -57px !important;
	top: 53px;
}

li.dropdown.dropdown-hovers ul.dropdown-menu.mmenu4 {
	background: transparent;
	text-align: left;
	margin: 0;
	padding: 0;
	border: none;
	min-width: 180px !important;
	height: 800px;
	box-shadow: none;
	left: -49px !important;
	top: 53px;
}

li.dropdown.dropdown-hovers ul.dropdown-menu.mmenu5 {
	background: transparent;
	text-align: left;
	margin: 0;
	padding: 0;
	border: none;
	min-width: 250px !important;
	height: 800px;
	box-shadow: none;
	left: -76px !important;
	top: 53px;
}




li.dropdown.dropdown-hovers ul.dropdown-menu.main-menu {
	background: transparent;
	text-align: left;
	margin: 0;
	padding: 0;
	border: none;
	min-width: 500px;
	height: 800px;
	box-shadow: none;
	left: -172px;
	top: 53px;
}

.menu-image-drop {
	background-position: left;
	background-size: cover;
	background-repeat: no-repeat;
}

.single-menu1 {
	height: 170px !important;

}

.single-menu4 {
	height: 210px !important;

}

.single-menu5 {
	height: 120px !important;

}

.desk_cargo .menu-full-pop {
	height: 292px;
}

.menu-full-pop {
	height: 340px;
	background-repeat: no-repeat;
	background-size: 90%;
	background-position: left;
	position: relative;
	background: #fff;
	border-radius: 0 0 30px 30px;
	border-top: 3px solid #fdc200;
	-webkit-box-shadow: 0px 1px 5px rgb(22 66 113);
	-moz-box-shadow: 0px 1px 5px rgb(22 66 113);
	box-shadow: 0px 1px 5px rgb(22 66 113);
	align-items: center;
}

.dropdown-menu .flag-height {
	height: 360px !important;
}

.dropdown-menu .flag-head {
	padding-left: 15px;
	min-width: 230px;
}

.head-menu-liss {
	position: absolute !important;
	left: 4%;
	top: 50%;
	transform: translateY(-50%);
}

li.dropdown.dropdown-hovers ul.dropdown-menu {
	background: #fff;
	text-align: left;
	margin: 0;
	padding: 0;
	border-radius: 0 0 30px 30px;
}

li.dropdown.dropdown-hovers ul li a:hover {
	margin-left: 10px;
}

li.dropdown.dropdown-hovers ul.dropdown-menu.main-menu {}

li.dropdown.dropdown-hovers:hover ul.dropdown-menu.main-menu {}

ul.dropdown-menu.flagg-drop {
	min-width: auto;
	top: 53px;
	margin-left: -210px !important;
}

.country-list-main.col-md-12 {
	padding: 0;
}

ul.dropdown-menu.flagg-drop:before {
	right: 179px !important;
}

li.dropdown.dropdown-hovers:hover ul.dropdown-menu.main-menu:before,
li.dropdown.dropdown-hovers:hover ul.dropdown-menu.flagg-drop:before {
	top: -14px;
	opacity: 1;
	color: #fff;
	transition: all .5s ease-out;
}

ul.dropdown-menu.main-menu:before,
ul.dropdown-menu.flagg-drop:before {
	content: '';
	position: absolute;
	right: 155px;
	text-align: center;
	top: 23px;
	z-index: -1;
	margin: 0 auto;
	opacity: 0;
	width: 0;
	height: 0;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-bottom: 15px solid rgb(253, 206, 4);
}

a.dropdown-item.dropdown-toggle.drop-inner {
	display: block;
	width: 100%;
	padding: 10px 10px;
	clear: both;
	font-weight: 400;
	color: #001524;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}

img.flags-dr {
	width: 40px !important;
}

.down-arrows img {
	width: 10px !important;
	margin: 5px 5px 0;
}

ul.flag-sec-respons li {
	position: relative;
}

.gsl_menu1,
.gsl_menu2,
.gsl_menu3,
.gsl_menu4,
.gsl_menu5 {
	display: flex;
	flex-direction: row-reverse;
}


.mmenu2 .gsl_menu2,
.mmenu3 .gsl_menu3,
.flagg-drop {
	width: 410px !important;
}

.desk_tools_ko .gsl_menu2 {
	width: 500px !important;
}


.gsl_menu2 .head-menu-liss {
	left: 4%;
}

.mmenu1::before {
	left: 89px !important;
}

.mmenu2::before {
	left: 70px !important;
}

.mmenu3::before {
	left: 110px !important;
}

.mmenu4::before {
	left: 80px !important;
}

.mmenu5::before {
	left: 113px !important;
}

ul.control-dots {
	display: none;
}

section.inner-center ul.nav.navbar-nav.flag-sec-respons {
	border-left: 1px solid #3a3a3a;
	padding-left: 12px;
}

article.banner-tabss {
	position: inherit;
	top: 0;
	margin: 0 auto;
	width: auto;
	text-align: center;
	left: 0;
	right: 0;
	background: #f6cf80;
	background-size: cover;
	background-image: url(./assets/bg_wave.png);
}

.recent-newss .slick-slide {
	float: left;
	height: auto !important;
	min-height: 1px;
}

.tab-content.ban-tab-cont {
	height: auto;
	position: relative;
}

ul.nav.nav-tabs.ban-tab-ul {
	border: none;
	text-align: center;
	display: inline-block;
}

ul.nav.nav-tabs.ban-tab-ul li.nav-item {
	display: inline-block;
	margin: 30px 10px;
}

ul.nav.nav-tabs.ban-tab-ul li.nav-item a.nav-link {
	border-radius: 50%;
	width: 100px;
	height: 100px;
	background: #fff;
	position: relative;
	border: 2px solid #fff;
	transition: all .5s ease-out;
}

ul.nav.nav-tabs.ban-tab-ul li.nav-item a.nav-link.active {
	background: #f98744;
	border: 2px solid #fff;
	color: #fff;
	transition: all .5s ease-out;
}

ul.nav.nav-tabs.ban-tab-ul li.nav-item a.nav-link.active .tab-icons img {
	filter: brightness(0) invert(1);
	-webkit-filter: brightness(0) invert(1);
	transition: all .5s ease-out;
}

ul.nav.nav-tabs.ban-tab-ul li.nav-item a.nav-link:hover {
	background: #f98744;
	border: 2px solid #fff;
	color: #fff;
	transition: all .5s ease-out;
}

ul.nav.nav-tabs.ban-tab-ul li.nav-item a.nav-link:hover .tab-iconshov {
	opacity: 1;
	transition: all .5s ease-out;
}

ul.nav.nav-tabs.ban-tab-ul li.nav-item a.nav-link:hover .tab-icons img {
	filter: brightness(0) invert(1);
	transition: all .5s ease-out;
	opacity: 0;
}

.tab-icons {
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	top: 50%;
	transform: translateY(-50%);
}

.tab-iconshov h2 {
	font-size: 14px;
	margin: 0;
	color: #fff;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
}

.tab-iconshov p {
	font-size: 14px;
	margin-top: 10px;
	text-transform: uppercase;
	color: #fff;
}

.tab-iconshov {
	opacity: 0;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	right: 0;
	margin: 0 auto;
	transition: all .5s ease-out;
}

.tabcon-input {
	position: inherit;
	left: 0;
	right: 0;
	margin: 0 auto;
	top: 0%;
	transform: translateY(-25px);
	width: 100%;
}

.tabcon-input input {
	background: #fff;
	width: 100%;
	z-index: 1;
	color: #000;
	border: none;
	padding: 15px 15px;
	border-radius: 50px;
	box-shadow: 0px 0px 21px 1px rgb(8, 8, 8);
	text-transform: uppercase;
}

.tabcon-input select {
	background: #fff;
	background: #fff;
	width: 100%;
	z-index: 1;
	color: #000;
	border: none;
	padding: 15px 15px;
	border-radius: 16px;
	border: none;
	box-shadow: 0px 0px 21px 1px rgb(8, 8, 8);
	-webkit-appearance: none;
}

.tabcon-input input::placeholder {
	color: rgb(199, 199, 199);
	text-transform: none;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
	font-weight: 100;
	letter-spacing: 1px;
}

.carco-track input:focus {
	outline: none !important;
}

.tabcon-input button {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background: #f98744;
	border: 2px solid #fff;
	margin-left: -50px;
	position: absolute;
	top: -18px;
	right: -5px;
}

#textContainerNumber:focus {
	outline: none;
}

.tabcon-input span.errors {
	width: 100%;
	position: absolute;
	color: #fff;
	left: 0;
	right: 0;
	bottom: -44px;
}

.tab-icons img {
	width: 40px;
}

.subscrib-in input {
	text-transform: inherit;
}

.tabcon-input.subscrib-in input::placeholder {
	text-transform: inherit;
}

sup.starss {
	font-size: 18px;
	top: -2px;
	right: -3px;
}

ul.flag-sec-respons li sup {
	font-size: 16px;
}

ul.ser-f-dropdown {
	list-style: none;
}

ul.ser-f-dropdown li {
	display: inline-block;
}

.ser-first {
	text-align: center;
}

.ser-first h2 {
	text-align: right;
	font-size: 55px;
	font-weight: 700;
}

section.align-section {
	background-repeat: no-repeat;
}

#clip {
	font-size: 34px;
	font-weight: 700;
	text-align: center;
	color: rgb(249, 135, 68);
	letter-spacing: 1px;
	margin-bottom: 20px;
	margin-top: 20px;
}

.ser-f-full {
	padding: 0px 0 0px;
}

.service-dr-1,
.service-dr-2,
.service-dr-3 {
	padding: 15px 10px;
}

.service-dr-1 button,
.service-dr-2 button,
.service-dr-3 button {
	padding: 10px 0;
	border-radius: inherit;
	border: none;
	width: 210px;
	font-size: 16px;
	font-weight: 500;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
}

.service-dr-1 button {
	background: #e1c15b;
}

.service-dr-2 button {
	background: #f28e44;
}

.service-dr-3 button {
	background: #f2af5a;
}

img.serv-icon {
	width: 32px;
	margin-right: 8px;
}

.map-container iframe {
	width: 100%;
	display: block;
	position: relative;
}

.map-container iframe.clicked {
	pointer-events: auto;
}

.map-container {
	width: 100%;
	height: 598px;
}

.ind-tab-mab {
	position: absolute !important;
	top: -50px;
}

.new-add-office {
	position: absolute;
	display: none;
	left: 100%;
	z-index: 1;
	top: 0;
}

.show {
	display: block;
}

.map-posi-addrs p {
	padding: 4px;
	margin: 0;
	font-size: 13px;
	font-weight: 500;
	color: #000;
}

.map-posi-addrs h5 {
	font-size: 18px;
	font-family: 'MyriadPro-Regular';
}

.gsl-rit-enn {
	background: #fff7ea;
}

.map-posi-addrs {
	padding: 20px 10px;
	border-bottom: 1px solid #b77b4a;
	border-bottom-style: dashed;
}

.map-posi-addrs a {
	color: #fff;
	background: #5cd0e1;
	padding: 4px 10px;
	border-radius: 12px;
}

.gsl-rit-enn {
	position: absolute;
	left: 100%;
	z-index: 1059;
	width: 100%;
	bottom: 0;
	height: 599px;
	top: 0;
	display: none;
	background: #fdfdfd;
	overflow-y: scroll;
	border-left: inherit;
	border-left-style: inherit;
}

ul.map-inner-tab li {
	width: 100%;
	color: #333;
}

ul.map-inner-tab li a {
	margin: 0;
	color: #333;
}

.tab-content.border.booking-tab-cont.map-full-sec {
	background: #f7f7f7;
}

.map-container .accordion-toggle h3 {
	background: #123c60;
	color: #fff;
	font-size: 16px;
	letter-spacing: 2px;
	font-weight: 700;
}

.accordion-panel.map-panel-inn {
	background: #fff;
	height: 444px;
	overflow: auto;
	padding: 0;
}

.ind-tab-mab ul li {
	background: #ccc !important;
	width: 49.4%;
	text-align: center;
}

.ind-tab-mab ul li a {
	padding: 20px 5px !important;
	padding: 15px 5px !important;
	height: 50px;
	font-size: 18px;
}

.accordion-panel.in-map-list {
	background: #fff;
	height: 496px;
	overflow: auto;
	padding: 0;
}

.map-panel-inn a {
	font-size: 16px;
	display: block !important;
	padding: 7px 10px;
	margin-left: 0;
	position: relative;
	border: none !important;
}

.map-arr-rit {
	margin-top: 0;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}

.map-arr-rit i {
	color: rgba(41, 41, 41, 0.69);
}

.fa-che {
	font-size: 12px !important;
	color: #ccc;
	margin: 5px;
}

.map-panel-inn ul li {
	border-bottom: 1px solid #ccc !important;
	margin: 0;
	padding: 0;
	position: relative;
}

span.map-t-round {
	font-size: 12px;
	text-align: center;
	background: #75cff0;
	color: #fff;
	border-radius: 50%;
	width: 35px;
	display: inline-block;
	height: 35px;
	line-height: 35px;
	margin-right: 10px;
}

.map-panel-inn ul li a:active {
	color: #000 !important;
}

.map-panel-inn ul li.active {
	background: #c3dff0;
	color: #fff;
}

.map-panel-inn ul li img {
	margin: 0px 10px 0px 0;
}

a.nav-link.flag-text.active.show {
	background: transparent;
	border: none;
}

.flags-acc {
	width: 32px;
}

body::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
	background-color: #b9b9b9;
	outline: 1px solid #365b7f;
}

.autocomplete-suggestions {
	max-height: 210px;
	width: 340px !Important;
	text-transform: capitalize;
	border: 1px solid rgba(153, 153, 153, 0.46);
	background: #fff;
	cursor: default;
	overflow: auto;
}

.autocomplete-suggestion {
	padding: 6px 5px;
	font-size: 13px;
	white-space: nowrap;
	overflow: hidden;
}

.autocomplete-selected {
	background: #f0f0f0;
}

.autocomplete-suggestions strong {
	font-weight: normal;
	color: #3399ff;
}

.tooltip {
	left: 0px;
	left: 50px !important;
}

.tooltip-inner {
	max-width: 200px !Important;
	width: 200px !Important;
	background: #000;
	padding: 10px 0px;
}

.dropdown-item.active,
.dropdown-item:active {
	color: #001524 !important;
	text-decoration: none;
	background-color: #fdc200 !important;
}

.flag-text {
	padding: 5px 0 0 0;
}

select.menu-countyy {
	border: none;
	padding: 5px 7px;
	margin: 5px;
	line-height: inherit;
	font-size: 14px;
	background: #dcdcdc;
	color: #001524;
	width: 100px;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
	font-weight: 400;
}

.sec-serv h4 {
	text-align: right;
	color: #fff;
	font-weight: 500;
	font-size: 24px;
	margin: 0;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.map-inner-tab ul li a {
	border-bottom: 1px solid #ccc;
}

.nav-tabs .nav-link:hover {
	border-color: transparent;
}

.port-sec-labb {
	margin: 0 0px 3px 3px;
	font-size: 14px;
	color: #fff;
}

ul.sec-portal {
	list-style: none;
	margin: 0;
	text-align: left;
	padding: 0;
	display: inherit;
}

ul.sec-portal li {
	display: inline-table;
	position: relative;
	width: 48%;
	margin-right: 4px;
}

ul.sec-portal li input {
	padding: 0px 20px;
	width: 100% !important;
	height: 35px;
	margin-right: 0;
	background: #ffffff;
	border: 1px solid rgba(255, 255, 255, 0.5607843137254902);
	margin-bottom: 3px;
	font-size: 12px;
	border-radius: 15px !important;
}

ul.sec-portal li select {
	padding: 0px 20px;
	width: 100%;
	height: 35px;
	margin-right: 0;
	-webkit-appearance: none;
	font-size: 12px;
	border-radius: 15px !important;
	-moz-appearance: none;
	-moz-border-radius: 0px !important;
	border: 1px solid rgba(255, 255, 255, 0.5607843137254902);
}

.custom-control-label {
	margin: 0 !important;
}

ul.sec-portal li a.select-arrow {
	position: absolute;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 8px solid #545454;
	right: 18px;
	margin: 0 auto;
	top: inherit;
	text-align: center;
	bottom: 13px;
}

ul.sec-pot-check.home-port {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	padding: 0;
}

ul.sec-pot-check li {
	display: inline-block;
	margin-right: 10px;
}

.phone-country {
	display: flex;
}

.phone-country input.contry1 {
	width: 15% !important;
	margin-right: 5px;
}

.phone-country input.contry2 {
	width: 85% !important;
}

.phone-country input.contry3 {
	width: 25% !important;
	margin-right: 5px;
}

.radio-nane {
	color: #fff;
	font-size: 16px;
}

button.portal-searc {
	height: 48px;
	width: 170px;
	border-radius: 5px;
	background: #9a9a9a;
	color: #fff;
	border: 1px solid #fff;
	cursor: pointer;
}

.portal-section {
	background: #5e5e5e;
	padding: 30px 0px;
	position: relative;
}

.custom-control-label::before {
	background-color: #fff;
}

#submitDetails img {
	width: 40px;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.2);
}

p.image-after {
	position: absolute;
	width: 0;
	height: 0;
	border-left: 45px solid transparent;
	border-right: 45px solid transparent;
	border-bottom: 48px solid #fff;
	top: 0px;
	left: 0;
	right: 0;
	text-align: center;
	margin: 0 auto !important;
	opacity: 0;
	transition: all .5s cubic-bezier(0.18, 0.89, 0.32, 1.28);
	padding: 0 !important;
}

p.image-after2 {
	position: absolute;
	width: 0;
	height: 0;
	border-left: 45px solid transparent;
	border-right: 45px solid transparent;
	border-top: 48px solid #ffffff;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	margin: 0 auto !important;
	opacity: 0;
	transition: all .5s cubic-bezier(0.18, 0.89, 0.32, 1.28);
	padding: 0 !important;
}

.solos-text:hover .image-after {
	opacity: 1;
	transition: all .5s cubic-bezier(0.18, 0.89, 0.32, 1.28);
	top: -32px;
}

.solos-text:hover .image-after2 {
	opacity: 1;
	transition: all .5s cubic-bezier(0.18, 0.89, 0.32, 1.28);
	bottom: -32px;
}

.door-solas {
	margin: 60px 0 0 0;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
}

.solor-section {
	padding: 0;
}

.solos-img-sec {
	position: relative;
	overflow: hidden;
}

.solor-section img {
	width: 100%;
}

.sol-regus {
	margin: 15px 0px;
}

.solos-text {
	height: 286px;
	text-align: center;
	padding: 40px 50px 0 50px;
	position: relative;
}

.solos-text h4 {
	font-size: 21px;
	font-weight: 600;
	text-transform: uppercase;
	padding-bottom: 20px;
	position: relative;
	color: #f98744;
}

.solos-text h4:after {
	content: '';
	position: absolute;
	top: 45px;
	width: 50px;
	height: 4px;
	background: #f98744;
	text-align: center;
	left: 0;
	right: 0;
	margin: 0 auto;
}

.solos-text p {
	font-size: 18px;
	text-align: center;
	padding: 25px 10px;
	line-height: 18px;
}

.solos-text a:hover {
	text-decoration: none;
	background: #f98744;
	color: #fff;
	transition: all .5s ease-out;
}

.solos-text a {
	background: transparent;
	border: 1px solid #f98744;
	color: #f98744;
	text-transform: uppercase;
	font-size: 12px;
	padding: 3px 10px;
	transition: all .5s ease-out;
}

.solos-text button {
	background: transparent;
	border: 1px solid #f98744;
	color: #f98744;
	text-transform: uppercase;
	font-size: 12px;
	padding: 3px 10px;
	transition: all .5s ease-out;
}

.sol-regus {
	margin: 26px 0px;
}

.sol-regus h5 {
	font-size: 14px;
	font-weight: 700;
}

.sol-regus input::placeholder {
	font-size: 12px;
}

.sol-regus input {
	width: 90%;
	padding: 2px 5px;
}

#clip2 {
	color: #f98744;
}

.ser-fuull-four {
	padding: 60px 0 0px 0;
	background: #f6fafc;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
}

h2.serv-fll-head {
	text-align: center;
	font-size: 40px;
	font-weight: 700;
	color: #f98744;
	margin-bottom: 10px;
}

.service-con-full-1 {
	background-image: url('./assets/sailing-schedule.jpg');
	background-repeat: no-repeat;
	background-size: cover;
}

.service-con-full-2 {
	background-image: url('./assets/equipment.jpg');
	background-repeat: no-repeat;
	background-size: cover;
}

.service-con-full-3 {
	background-image: url('./assets/port-code.jpg');
	background-repeat: no-repeat;
	background-size: cover;
}

.service-con-full-4 {
	background-image: url('./assets/rotations.jpg');
	background-repeat: no-repeat;
	background-size: cover;
}

.service-con-full-1,
.service-con-full-2,
.service-con-full-3,
.service-con-full-4 {
	height: 480px;
	opacity: 0;
	transition: all 1s ease-out;
}

div .inside-service-h {
	height: 400px;
}

div .in-ser-ttext img {
	width: 40px;
}

div .in-ser-ttext h4 {
	font-size: 16px;
}

div .in-ser-ttext p {
	font-size: 14px;
	padding: 0 10px;
	-webkit-line-clamp: 2;
	display: -webkit-box;
	overflow: hidden;
	-webkit-box-orient: vertical;
	height: 45px;
}

.service-full-sche:hover .service-con-full-1,
.service-full-sche:hover .service-con-full-2,
.service-full-sche:hover .service-con-full-3,
.service-full-sche:hover .service-con-full-4 {
	opacity: 1;
	transition: all 1s cubic-bezier(0.39, 0.58, 0.57, 1);
}

.service-full-sche {
	position: relative;
}

.serv-inner-full {
	width: 100%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	margin: 0 auto;
	left: 0;
	right: 0;
	text-align: center;
	width: 80%;
}

.serv-inner-full h4 {
	font-size: 18px;
	font-weight: 700;
	text-transform: capitalize;
	color: #f98744;
}

.serv-inner-full p {
	color: #222426;
	padding: 0 55px;
	margin: 0 0 50px 0;
}

.serv-inner-full a {
	padding: 20px 0px;
	background: rgba(50, 184, 119, 1);
	background: -moz-linear-gradient(left, rgba(50, 184, 119, 1) 0%, rgba(55, 166, 183, 1) 31%, rgba(55, 166, 183, 1) 50%, rgba(55, 166, 183, 1) 72%, rgba(50, 184, 119, 1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(50, 184, 119, 1)), color-stop(31%, rgba(55, 166, 183, 1)), color-stop(50%, rgba(55, 166, 183, 1)), color-stop(72%, rgba(55, 166, 183, 1)), color-stop(100%, rgba(50, 184, 119, 1)));
	background: -webkit-linear-gradient(left, rgba(50, 184, 119, 1) 0%, rgba(55, 166, 183, 1) 31%, rgba(55, 166, 183, 1) 50%, rgba(55, 166, 183, 1) 72%, rgba(50, 184, 119, 1) 100%);
	background: -o-linear-gradient(left, rgba(50, 184, 119, 1) 0%, rgba(55, 166, 183, 1) 31%, rgba(55, 166, 183, 1) 50%, rgba(55, 166, 183, 1) 72%, rgba(50, 184, 119, 1) 100%);
	background: -ms-linear-gradient(left, rgba(50, 184, 119, 1) 0%, rgba(55, 166, 183, 1) 31%, rgba(55, 166, 183, 1) 50%, rgba(55, 166, 183, 1) 72%, rgba(50, 184, 119, 1) 100%);
	background: linear-gradient(to right, rgba(50, 184, 119, 1) 0%, rgba(55, 166, 183, 1) 31%, rgba(55, 166, 183, 1) 50%, rgba(55, 166, 183, 1) 72%, rgba(50, 184, 119, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#32b877', endColorstr='#32b877', GradientType=1);
	position: relative;
	color: #fff;
	padding: 13px 35px;
	border-radius: 0px;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 1px;
}

.serv-inner-full img {
	width: 60px;
	margin-bottom: 20px;
	transition: all .5s ease-out;
}

.service-full-sche:hover .serv-inner-full img {
	-webkit-filter: brightness(100);
	filter: brightness(100);
	transition: all .5s cubic-bezier(0.39, 0.58, 0.57, 1);
}

.service-full-sche:hover .serv-inner-full h4,
.service-full-sche:hover .serv-inner-full p {
	color: #fff;
}

.serv-inner-full a:hover {
	background: #fff;
	color: #000;
	text-decoration: none;
}

.recent-newss {
	margin: 60px 0;
}

.slider1 .slide {
	padding: 0 10px;
	overflow: hidden;
}

.news-img-rotate {
	overflow: hidden;
	height: 230px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.slider1 .slide img {
	width: 100%;
	overflow: hidden;
	transition: all .5s ease-out;
}

.slider1 .slide:hover .img-responsive {
	transform: scale(2, 2) rotate(10deg);
	transition: all .5s ease-out;
}

.slider1 .slick-list {
	position: relative;
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 0 !important;
}

.recent-n-inner {
	text-align: left;
	padding: 10px 15px;
	font-family: 'Muli', sans-serif;
}

.recent-des {
	color: #131149;
	padding: 5px 0 0px 0;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	height: 60px;
	font-size: 18px;
}

.recent-date {
	color: #4ab9d6;
	font-size: 18px;
	margin: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.text-news-t {
	color: #4ab9d6;
	font-size: 18px;
	margin: 0;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	height: 30px;
}

a.recent-read {
	color: #4ab9d6;
	font-size: 14px;
	padding: 17px 0;
	font-weight: lighter;
	transition: all .5s ease-out
}

.recent-headd {
	text-align: center;
	margin-bottom: 40px;
	color: #f98744;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
}

.recent-headd h2 {
	font-size: 34px;
	font-weight: 700;
}

.view-all-ind-f-full {
	text-align: center;
	display: block;
	background: #f98744;
	color: #fff;
	width: 100px;
	padding: 7px 10px;
	margin: 0 auto;
}

.slider1 ul.slick-dots li button {
	font-size: 0;
	line-height: 0;
	display: block;
	width: 7px;
	height: 7px;
	padding: 5px;
	cursor: pointer;
	color: #6fdee8;
	border: 0;
	outline: none;
	background: #3a3864;
	border-radius: 50%;
}

.slider1 ul.slick-dots {
	bottom: -40px;
}

.caret {
	margin: 5px 0px;
}

.slider1 ul.slick-dots li.slick-active button {
	background: #f98744;
}

.recent-n-inner:hover .slide img {
	width: 100%;
}

.countr-seperate img,
.countr-seperate h4,
.countr-seperate p {
	margin-bottom: 30px !important;
}

a.recent-read img {
	width: 14px !important;
	display: inline-block;
	margin-left: 2px;
	transition: all .5s ease-out
}

a.recent-read:hover {
	text-decoration: none;
	letter-spacing: 2px;
	transition: all .5s ease-out
}

a.recent-read:hover .news-knoww {
	margin-left: 10px;
	transition: all .5s ease-out
}

.indus-botm {
	margin: 80px 0 60px;
}

.count-back {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

ul.countr-seperate {
	list-style: none;
	text-align: center;
	margin: 0;
	padding: 70px 0 40px 0;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
}

ul.countr-seperate li {
	display: inline-block;
	padding: 0 20px;
	width: 24%;
	text-align: left;
	text-align: center !important;
}

ul.countr-seperate li img {
	width: 60px;
	margin-bottom: 20px;
}

ul.countr-seperate li h4 {
	color: #fff;
	font-size: 30px;
	font-weight: 700;
}

ul.countr-seperate li p {
	color: #fff;
	margin-top: 1px;
	font-weight: lighter;
	font-size: 18px;
}

section.form-fulll {
	margin: 60px 0;
}

.form-head h2 {
	font-size: 40px;
	text-transform: uppercase;
	font-weight: 700;
	margin-bottom: 70px;
	position: relative;
	color: #f98744;
}

.form-head h2:after {
	content: '';
	position: absolute;
	top: 82px;
	width: 50px;
	height: 5px;
	background: #f98744;
	text-align: center;
	left: 0;
	right: 0;
	margin: 0 auto;
}

.form-head {
	text-align: center;
	margin-bottom: 60px;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
}

.form-inputs {
	text-align: center;
}

.form-head p {
	font-size: 16px;
	color: #212121a3;
	width: 75%;
	margin: 0 auto;
	line-height: 30px;
}

ul.contact-form-eq li {
	display: inline-block;
	width: 100%;
	margin: 6px 0;
}

ul.contact-form-eq {
	list-style: none;
	margin: 0;
	font-family: 'Open Sans', sans-serif;
	padding: 0;
}

ul.contact-form-eq li input,
ul.contact-form-eq li select {
	width: 100%;
	background: rgb(245, 249, 252) !important;
	border: 1px solid rgba(210, 210, 210, 0.2);
	padding: 12px 15px;
	color: #212121ba;
	font-size: 14px
}

ul.contact-form-eq li input::placeholder {
	color: #212121ba;
}

textarea.eq-text-area {
	height: 150px;
	margin-top: 10px;
	width: 100%;
	background: #f5f9fc;
	color: #212121;
	padding: 12px;
	border: 1px solid rgba(210, 210, 210, 0.2);
	font-family: 'Open Sans', sans-serif;
}

ul.contact-form-eq li.ind-f-full {
	width: 100%;
}

ul.contact-form-eq li select option {
	background: #ffffff;
	color: #212121;
	margin: 20px;
}

button.but-eqy {
	background: #6fdee8;
	color: #fff;
	padding: 14px 30px;
	border-radius: 18px;
	border: none;
	text-align: center;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 14px;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
	display: block;
	margin: 15px auto;
}

.form-rt-addrs h5 {
	font-size: 14px;
	font-family: 'Open Sans', sans-serif;
	margin-top: 25px;
	color: #212121bd;
	font-weight: 500;
}

.form-rt-addrs p {
	font-size: 14px;
	font-family: 'Open Sans', sans-serif;
	margin-top: 12px;
	color: #212121;
	font-weight: 600;
}

.form-right {
	width: 70%;
	padding: 20px;
	font-family: 'Open Sans', sans-serif;
}

.form-rt-addrs {
	text-align: center;
	margin: 15px 0 0px 0;
}

.footer-section {
	background: #143e68;
	padding-bottom: 80px;
}



.footer-contain {
	padding: 50px 0 30px;
}

ul.footer-list-quk {
	list-style: none;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
	padding: 0;
}

ul.footer-list-quk li {
	margin-bottom: 10px;
}

ul.footer-list-quk h4 {
	margin-bottom: 20px;
}

ul.footer-list-quk h4 a {
	font-family: 'FSIndustrieNw-Regular', sans-serif;
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 1px;
	color: #fff;
}

ul.footer-list-quk li a {
	color: #fff;
	font-size: 16px;
	padding: 0;
	font-family: 'FSIndustrieNw-Regular';
}

ul.footer-list-quk.others-links {
	text-align: left;
	padding: 0;
	margin: 0;
}

ul.footer-list-quk.others-links li {
	display: inline-block;
	margin: 0;
	width: 100%;
}

ul.footer-list-quk.others-links li a {
	padding: 0px;
	color: #fff;
}

.footer-section a:hover {
	text-decoration: none;
}

i.fa.fa-map-marker {
	font-size: 18px;
}

.view {
	margin: 10px 0;
	color: #f98744;
	font-weight: bold;
	font-size: 16px;
}

.footer-subs {
	position: relative;
}

.footer-subs h4 {
	font-size: 16px;
	color: #fff;
	margin-bottom: 20px;
	font-weight: bold;
	padding: 0px;
	text-transform: uppercase;
}

.footer-subs input {
	outline: none;
	width: 100%;
	padding: 9px 15px;
	font-size: 14px;
	border-radius: 25px;
	border: 1px solid #e4e7ec;
	font-family: 'FSIndustrieNw-Regular';
}

.footer-subs input:placeholder {
	color: #a5adbb;
	font-weight: 100;
}

.bottom-fixed-bar-search {
	position: fixed;
	bottom: 0px;
	background: #fdc200;
	z-index: 1;
}

.bottom-fixed-bar-search .footer-bar-tracker-box input {
	outline: none;
	width: 100%;
	padding: 12px 25px;
	font-size: 14px;
	/* border-radius: 25px; */
	border: 1px solid #fdc200;
	position: relative;
	font-family: 'FSIndustrieNw-Regular';
	background: #feda66;
	border: 1px solid #eeeeee;
}

.bottom-fixed-bar-search .footer-bar-tracker-box button {
	padding: 12px 20px;
	position: absolute;
	right: 0;
	background: #143e68;
	top: 0;
	border: 1px solid transparent;
	font-size: 14px;
	/* border-bottom-right-radius: 25px; */
	/* border-top-right-radius: 25px; */
	font-family: 'FSIndustrieNw-Regular';
	color: #fdc200;
	font-weight: 600;
}

.bottom-fixed-bar-search .footer-bar-tracker-box button img {
	width: 30px;
}

.footer-bar-button-box ul li {
	text-align: center;
	flex-grow: 1;
	border-right: 1px solid #fff;
	margin-right: 0px !important;
	cursor: pointer;
}

.footer-bar-tracker-box {
	margin: 12px 0px;
}

.footer-bar-button-box ul li:nth-child(5) {
	border: none;
}

.footer-bar-button-box ul li:hover {
	flex-grow: 1;
	transition: all 0.5s;
}

.footer-bar-button-box ul li img {
	width: 25px;
	display: inline-block;
}



.footer-bar-button-box ul li span {
	display: block;
	font-family: FSIndustrieNw-Regular;
	font-weight: 500;
	font-size: 16px;
}

.footer-bar-button-box ul li a {
	color: #001524;
	text-decoration: none;

}

.footer-bar-button-box ul.list-inline.d-flex {
	height: 100%;
	align-items: center;
}

.footer-bar-button-box ul li a:hover {
	text-decoration: none;
}

.fadeOut {
	opacity: 0;
	display: none;
	width: 0;
	height: 0;
	transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;

}

.fadeIn {
	opacity: 1;
	width: 100px;
	height: 100px;
	transition: width 0.3s, height 0.3s, opacity 0.3s 0.3s;

}

div#container_model {
	position: fixed;
	right: 6%;
	background: #fff;
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
	width: 63%;
	height: auto;
	bottom: 70px;
	box-shadow: inset #e1e1e1 0 0 9px, #fff 0 0 5px;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
	background-color: #174173 !important;
}

.slabel {
	color: #174173;
	font-size: 15px;
	text-transform: uppercase;
}

.themebtn {
	background: #174173;
	border: none;
	padding: 7px 18px;
	font-size: 15px;
	color: #fff;
	border-radius: 20px;
}

.footer-bottom-popup-btn {
	position: fixed;
	bottom: 70px;
	right: 13px;
	background: #fdc200;
	padding: 13px;
	border-top-right-radius: 25px;
	border-top-left-radius: 25px;
}

.footer-bottom-popup-btn.open {
	bottom: 0px;
	/*animation: bounce 5.5s;*/
	animation-direction: alternate;
	animation-timing-function: cubic-bezier(.5, 0.5, 0.6, .5);
	animation-iteration-count: infinite;
}

.footer-bottom-popup-btn.open a img {
	transform: scale(-1, -1);
}

.s-active:before {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 15px solid #fff;
	clear: both;
}

.s-active {
	background: #143e68;
}

.s-active a {
	color: #fff !important;
}

.rr-padd {
	padding-top: 125px;
}

#container_model .sinput {
	outline: none;
	width: 100%;
	padding: 7px 13px;
	font-size: 14px;
	border-radius: 25px;
	background: #efefef;
	border: 1px solid #e4e7ec;
}

#enquire .form-inputs {
	background-color: #fff;
	padding: 0 30px;
}

button.but-eqy {
	background: rgba(50, 184, 119, 1);
	background: -moz-linear-gradient(left, rgba(50, 184, 119, 1) 0%, rgba(55, 166, 183, 1) 31%, rgba(55, 166, 183, 1) 50%, rgba(55, 166, 183, 1) 72%, rgba(50, 184, 119, 1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(50, 184, 119, 1)), color-stop(31%, rgba(55, 166, 183, 1)), color-stop(50%, rgba(55, 166, 183, 1)), color-stop(72%, rgba(55, 166, 183, 1)), color-stop(100%, rgba(50, 184, 119, 1)));
	background: -webkit-linear-gradient(left, rgba(50, 184, 119, 1) 0%, rgba(55, 166, 183, 1) 31%, rgba(55, 166, 183, 1) 50%, rgba(55, 166, 183, 1) 72%, rgba(50, 184, 119, 1) 100%);
	background: -o-linear-gradient(left, rgba(50, 184, 119, 1) 0%, rgba(55, 166, 183, 1) 31%, rgba(55, 166, 183, 1) 50%, rgba(55, 166, 183, 1) 72%, rgba(50, 184, 119, 1) 100%);
	background: -ms-linear-gradient(left, rgba(50, 184, 119, 1) 0%, rgba(55, 166, 183, 1) 31%, rgba(55, 166, 183, 1) 50%, rgba(55, 166, 183, 1) 72%, rgba(50, 184, 119, 1) 100%);
	background: linear-gradient(to right, rgba(50, 184, 119, 1) 0%, rgba(55, 166, 183, 1) 31%, rgba(55, 166, 183, 1) 50%, rgba(55, 166, 183, 1) 72%, rgba(50, 184, 119, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#32b877', endColorstr='#32b877', GradientType=1);
	color: #fff;
	padding: 14px 30px;
	border-radius: 0px;
	border: none;
	text-align: center;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 14px;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
	display: block;
	margin: 15px auto;
}

.footer-subs button {
	position: absolute;
	padding: 9px 17px;
	right: 0;
	background: #ffc334;
	bottom: 0;
	border: 1px solid transparent;
	font-size: 14px;
	border-radius: 25px;
	font-family: 'FSIndustrieNw-Regular';
	font-weight: bold;
	color: #000;
	font-weight: 600;
}

.copy-right {
	border-top: 1px solid #e4e7ec;
	padding: 35px 0 20px;
}

.footer-copy-rt {
	width: 90%;
	margin: 0px auto;
}

ul.footer-social {
	list-style: none;
	text-align: right;
	margin-bottom: 0;
}

ul.footer-social li {
	display: inline-block;
	margin-left: 15px;
}

ul.footer-social li a img {
	width: 24px;
}

ul.footer-social li a img.wechat {
	width: 35px;
}

.foot-cop-ang {
	font-family: 'FSIndustrieNw-Regular', sans-serif;
	color: #a5adbb;
}

ul.schedu-in-page {
	padding: 15px 0;
	text-align: center;
	margin: 0 auto;
	margin: 0;
}

ul.schedu-in-page li {
	display: inline-block;
}

ul.schedu-in-page li a {
	padding: 8px 17px;
	margin: 0 10px;
	color: rgb(255, 255, 255);
	border: 1px solid rgba(255, 255, 255, 0.83);
	border-radius: 30px;
	box-shadow: 0px 0px 4px 0px rgb(255, 255, 255);
	transition: all .3s ease-out;
}

ul.schedu-in-page li a:hover {
	background: rgba(255, 255, 255, 0.85);
	color: #f88240;
	transition: all .3s ease-in;
}

.question {
	position: relative;
	right: 0;
	color: #6fdee8;
	bottom: -30px;
	cursor: pointer;
	margin: 0 auto;
}

.question1 {
	position: absolute;
	right: 0;
	color: #6fdee8;
	bottom: -30px;
	cursor: pointer;
	margin: 0 auto;
	text-align: center;
	height: 26px;
}

.question i {
	color: #f88240;
	background: #fff;
	width: 30px;
	height: 30px;
	margin: 0 auto;
	line-height: 30px;
	border-radius: 50%;
}

.hidetext {
	display: none;
	transition: all .5s ease-in;
	background: #fff;
	color: #282828;
	padding: 14px 12px;
	position: absolute;
	bottom: 30px;
	border: 1px solid rgba(24, 22, 22, 0.14);
	left: 0;
	right: 0;
	width: 100%;
	margin: 0 auto;
}

.show {
	display: block;
}

span.error {
	color: #fff;
	font-size: 13px;
	font-weight: 400;
}

section.background-inner {
	position: relative;
	margin-top: 65px;
}

.banner-inside {
	height: 400px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.banner-inside h2 {
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	top: 50%;
	transform: translateY(-50%);
	font-family: 'FSIndustrieNw-Regular', sans-serif;
	color: #fff;
}

section.inner-center {
	margin: 80px 0;
}

article.inside-ban-article {
	position: absolute;
	top: 50%;
	margin: 0 auto;
	width: auto;
	text-align: center;
	left: 0;
	right: 0;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
}

div.ban-tab-cont.in-banne-art-con {
	height: 100px;
}

ul.banner-in-tablist li a {
	width: 80px !important;
	height: 80px !important;
}

ul.banner-in-tablist li {
	margin: 0 10px !important;
}

ul.banner-in-tablist li a .tab-icons img {
	width: 30px;
}

ul.banner-in-tablist li a .tab-iconshov h2 {
	font-size: 12px;
}

.ser-ins-mar {
	margin-bottom: 80px;
	background: #f6fafc;
	padding: 0;
}

.pot-form label {
	width: 100%;
	font-size: 16px;
	color: #7b7b7b;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
	font-weight: 400;
	margin: 10px 0 5px;
}

.pot-form input {
	width: 100% !important;
	padding: 11px;
	background: transparent !important;
	border: 1px solid rgba(0, 0, 0, 0.2);
	font-family: 'FSIndustrieNw-Regular', sans-serif;
}

.pot-form input::placeholder {
	color: #00000026;
}

.pot-form input:focus,
button:focus {
	outline-offset: 0;
	outline: -webkit-focus-ring-color auto 0px;
}

.pot-form .start-date-btn,
.pot-form .tare-cont-no,
.pot-form .track-cont-no {
	align-self: end;
	margin-bottom: 0px;
}

.pot-form .tare-cont-no .form-group,
.pot-form .track-cont-no .form-group {
	margin: 0px;
}

.tare-cont-no button.btn.btn-default.portal-searc-tariff,
.track-cont-no button.btn.btn-default.portal-searc-tariff {
	width: 170px;
	height: 49px;
	font-size: 18px;
}

.start-date-btn button.btn.btn-default.portal-searc-tariff {
	width: 100% !important;
	height: 49px;
}

.booking-tab-cont .vgm-form-button {
	text-align: center;
}

.vgm-form-button button.btn.btn-default.vgm-btn-submit {
	width: 170px;
	height: 48px;
}

.d-flex.si-booking-next img {
	margin-bottom: 10px;
}

.siv_main_box {
	float: left;
}

.siv_main_box .siv_img_bg,
.siv_main_box .siv_img_bg1 {
	min-height: 80px;
	background-repeat: no-repeat;
	background-position: right;
	box-shadow: -4px 5px 15px 2px #ccc;
	background-size: 52px;

}

.siv_main_box .siv_img_bg h3,
.siv_main_box .siv_img_bg1 h3 {
	font-weight: bold;
	font-size: 12px;
	font-family: FSIndustrieNw;
	padding: 33px 63px 25px 0px;
	color: #123c60;
}

.accordion-on.h3 .btn-group {
	font-size: 16px;
	color: #fff;
}

.faq-heading h2 {
	font-weight: 600;
	font-size: 30px !important;
	padding: 10px 0;
	margin: 0;
}

.sche-title p {
	font-size: 15px;
	font-weight: 500;
	text-align: center;
	margin-bottom: 0;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
	color: #fff;
}

.sche-title h3 {
	text-align: center;
	font-size: 34px;
	font-weight: 500;
	text-align: center;
	color: #fff;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
}

#datepicker>span:hover {
	cursor: pointer;
}

.pot-form select.selectpicker {
	width: 100%;
	padding: 11px;
	background: transparent !important;
	background: #f9874414;
	border: 1px solid rgba(0, 0, 0, 0.2);
	margin-bottom: 6px;
	font-family: 'FSIndustrieNw-Regular'
}

.datepicker {
	padding: 0 !important;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	direction: ltr;
}

ul.sec-pot-check.new-pot-radio li div label {
	color: #000;
}

ul.sec-pot-check.new-pot-radio li div label.custom-control-label::before {
	background-color: #ffc49d;
}

ul.sec-pot-check.new-pot-radio li {
	text-align: left;
}

ul.sec-pot-check.new-pot-radio li.buts-in-cen {
	text-align: left;
	width: 100%;
}

ul.sec-pot-check.new-pot-radio {
	text-align: left;
	padding: 0;
	width: 100%;
}

.form-fback {
	padding: 30px;
}

.contatc_padd .form-fback {
	padding: 0;
}

ul.sec-pot-check.new-pot-radio li button {
	margin-top: 10px;
	height: 48px;
	width: 170px;
	font-size: 18px;
}

.inn-sche-ban-sec h2 {
	width: 100%;
	font-size: 24px;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
	font-family: 'Roboto-Light_0';
	font-weight: 600;
	color: #754428;
}

.inn-sche-ban-sec p {
	width: 100%;
}

.inn-sche-ban img {
	width: 100%;
	margin-bottom: 40px;
	margin-top: 5px;
}

.inn-sche-ban-sec a:hover {
	background: #b95821;
	color: #fff;
	letter-spacing: 2px;
	transition: all .5s ease-out;
}

.inn-sche-ban-sec a {
	width: 100%;
	display: block;
	font-size: 18px;
	color: #001524;
	font-family: 'Roboto-Light_0';
	padding: 12px 10px;
	margin: 2px 0;
	transition: all .5s ease-in;
	border-bottom: 1px solid #ffdbc273;
}

.inn-sche-ban-sec {
	margin: 12px;
	background: #fff6f047;
	padding: 5px 5px 0;
	box-shadow: 0px 0px 8px 0px #00000014;
}

.inn-sche-ban p {
	font-size: 14px;
	font-family: 'Muli', sans-serif;
	margin-bottom: 5px;
}

form.point-to {
	padding: 10px 20px;
	background-color: #fff;
	padding: 0;
	border: 1px solid #dee2e6 !important;
	margin: 0 0px;
}

.sche-title {
	margin-bottom: 25px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding: 50px 0;
}

.inn-sche-list a:hover {
	letter-spacing: 1px;
	transition: all .5s ease-out;
	color: #b77b4a;
}

.inn-sche-list a {
	display: inline-block;
	width: 49%;
	font-size: 15px;
	padding: 15px 0;
	color: #7b7b7b;
	transition: all .5s ease-in;
}

.inn-sche-list a img {
	width: 35px;
	margin-right: 15px;
}

.second-inn-id {
	margin-top: 65px;
}

button.portal-searc.vessel-sear {
	margin-top: 8px !important;
}

.trade-tit p {
	margin-bottom: 4px;
}

.trade-head-bottom {
	text-align: center;
}

.trade-head-bottom p {
	margin-bottom: 4px;
	display: inline-block;
	text-align: center;
	padding: 12px;
	font-family: 'Muli', sans-serif;
}

ul.trade-tab-li {
	border: none;
}

ul.trade-tab-li li.nav-link.active {
	background-color: #143d68;
}

ul.trade-tab-li .nav-link {
	border: 0px !important;
}

ul.trade-tab-li li.nav-link.active a {
	color: #fff !important;
}

ul.trade-tab-li li a {
	color: #3c3c3c !important;
	font-weight: 700;
	letter-spacing: 1px;
	padding: 4px 5px;
}

ul.trade-tab-li li {
	background: rgb(234, 234, 234);
	margin-right: 2px;
}

.trade-tab-con {
	padding: 20px;
	background: #f1f1f1;
}

.trade-tab-back {}

ul.nav-tabs.trade-tab-li li a.active {
	background: #123c60;
}

ul.nav-tabs.trade-tab-li li a.active {
	color: #fff !important;
}

.trade-tab-con p {
	text-align: left;
	margin-bottom: 6px;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
	font-size: 16px;
	color: #001524;
}

.carrer-pops h4 {
	text-align: center;
	font-size: 21px;
}

.booking-tab-back {
	margin: 60px 0 40px;
}

.booking-tab-cont {
	background: #fffdf9;
}

h3.solas.h {
	font-size: 22px;
	font-weight: bolder;
	padding-bottom: 10px;
}

ul.protc-dat li {
	line-height: 30px;
	font-size: 18px;
	text-align: justify;
}

.data-policy-para p {
	line-height: 30px;
	font-size: 18px;
	text-align: justify;
}

.cookie-bg {
	background: #143e68;
	max-width: 525px;
	color: #fff;
	flex-grow: 1;
	padding: 10px 22px;
	line-height: 30px;
	position: fixed;
	border-radius: 5px;
	right: 5px;
	bottom: 5px;
	z-index: 100;
}

.cookie-text h6 {
	font-size: 24px;
	font-weight: 500;
	padding-bottom: 10px;
}

.cookie-text p {
	font-family: 'FSIndustrieNw';
	font-weight: 300;
	line-height: 25px;
}

.btn.cokie-yel {
	background-color: #fdc200 !important;
	color: #fff;
}

.cookie-btn {
	margin-right: 16px;
	width: 110px;
	padding: 5px !important;
	margin-top: 12px;
	font-size: 18px !important;
	font-weight: 400 !important;
}

.cookie-text button.close {
	opacity: 1;
}

.cookie-text button.close span {
	color: #fff;
	font-weight: 300;
	font-size: 36px;
	position: absolute;
	top: 10px;
	right: 20px;
}

div#toolsSlider .slick-slide {
	text-align: left !important;
}

.carrer-pops form label {
	font-size: 14px;
}

.carrer-pops form input {
	border-radius: inherit;
	padding: 12px 8px;
	background: #f5f9fc;
	border: 1px solid rgb(226, 226, 226);
}

.carrer-pops form {
	padding: 25px 20px;
}

.car-pop-but {
	background: #fb8902;
	height: inherit;
	width: inherit;
	margin: 10px auto 0;
	line-height: inherit;
	padding: 5px 20px;
	border-radius: 0;
	font-size: 16px;
	display: block;
	color: #fff;
}

.booking-next button {
	width: 205px;
	padding: 10px 15px;
	margin: 30px 15px;
	height: 48px;
	border-radius: 5px;
	background: #123c60;
	color: #fff;
	border: 1px solid #fff;
	text-align: center;
}

tr.off-desk-datil th {
	width: 20%;
	background: #f7f7f7;
	font-weight: 500;
	border: none;
}

tr.off-con-dateails th {
	width: 25%;
	background: #f7f7f7;
	font-weight: 500;
	border: none;
}

tr.data-off-table td {
	border-bottom: 1px solid #f1f1f1;
}

.phone-labb {
	padding: 10px;
	width: auto !important;
	background: #f5f9fc;
	margin: 0 !important;
}

.select-arrow-header {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 8px solid rgb(0, 0, 0);
	right: 14px;
}

.booking-next {
	text-align: center;
	margin: 0 auto;
	width: 100%;
	display: block !important;
}

.btn:focus,
.btn.focus,
textarea,
select {
	outline: 0;
	box-shadow: none;
	-moz-appearance: none;
	-webkit-appearance: none;
}

.pot-form textarea {
	width: 100% !important;
	height: 120px;
	padding: 10px;
	background: #f5f9fc;
	border: 1px solid rgba(210, 210, 210, 0.2);
	font-family: 'FSIndustrieNw-Regular', sans-serif;
	margin-bottom: 18px !important;
}

.booking-heads h4 {
	margin-bottom: 24px;
}

input.add-new-but {
	color: #fff;
	background: #fb8902 !important;
	width: 25% !important;
	text-align: center;
	margin: 0 auto 35px;
	display: block;
	padding: 10px;
}

.subs-add-cc label {
	width: 100%;
}

.subs-add-cc {
	text-align: center;
}

.abt-hist p img {
	width: 25px;
	padding-right: 10px;
}

.d-flex.booking-next a {
	width: 183px;
	padding: 10px 15px;
	margin: 30px 15px;
	height: 45px;
	border-radius: 19px;
	background: #fb8902;
	color: #fff;
	border: 1px solid #fff;
	text-align: center;
}

.checkbox-animated {
	position: relative;
	margin-top: 10px;
	margin-bottom: 10px;
}

.checkbox-animated input[type=checkbox] {
	display: none;
}

.checkbox-animated input[type=checkbox]:disabled~label .box {
	border-color: #777;
	background-color: #E6E6E6;
}

.checkbox-animated input[type=checkbox]:disabled~label .check {
	border-color: #777;
}

.checkbox-animated input[type=checkbox]:checked~label .box {
	opacity: 0;
	-webkit-transform: scale(0) rotate(-180deg);
	-moz-transform: scale(0) rotate(-180deg);
	transform: scale(0) rotate(-180deg);
}

.checkbox-animated input[type=checkbox]:checked~label .check {
	opacity: 1;
	-webkit-transform: scale(1) rotate(45deg);
	-moz-transform: scale(1) rotate(45deg);
	transform: scale(1) rotate(45deg);
}

.checkbox-animated label {
	cursor: pointer;
	padding-left: 40px;
	font-weight: normal;
	margin-bottom: 0;
}

.checkbox-animated label span {
	display: block;
	position: absolute;
	left: 0;
	-webkit-transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	transition-duration: 0.3s;
}

.checkbox-animated label .box {
	border: 2px solid #7b7b7b;
	height: 20px;
	width: 20px;
	z-index: 0;
	-webkit-transition-delay: 0.2s;
	-moz-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.checkbox-animated label .check {
	top: -7px;
	left: 6px;
	width: 12px;
	height: 24px;
	border: 2px solid #fb8902;
	border-top: none;
	border-left: none;
	opacity: 0;
	z-index: 95;
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	transform: rotate(180deg);
	-webkit-transition-delay: 0.3s;
	-moz-transition-delay: 0.3s;
	transition-delay: 0.3s;
}

#checkboxSubscribercontainer .checkbox-animated label .check {
	z-index: 0 !important;
}

.checkbox-animated-inline {
	position: relative;
	margin-top: 10px;
	margin-bottom: 10px;
}

.checkbox-animated-inline input[type=checkbox] {
	display: none;
}

.checkbox-animated-inline input[type=checkbox]:disabled~label .box {
	border-color: #777;
	background-color: #E6E6E6;
}

.checkbox-animated-inline input[type=checkbox]:disabled~label .check {
	border-color: #777;
}

.checkbox-animated-inline input[type=checkbox]:checked~label .box {
	opacity: 0;
	-webkit-transform: scale(0) rotate(-180deg);
	-moz-transform: scale(0) rotate(-180deg);
	transform: scale(0) rotate(-180deg);
}

.checkbox-animated-inline input[type=checkbox]:checked~label .check {
	opacity: 1;
	-webkit-transform: scale(1) rotate(45deg);
	-moz-transform: scale(1) rotate(45deg);
	transform: scale(1) rotate(45deg);
}

.checkbox-animated-inline label {
	cursor: pointer;
	padding-left: 28px;
	font-weight: normal;
	margin-bottom: 0;
}

.checkbox-animated-inline label span {
	display: block;
	position: absolute;
	left: 0;
	-webkit-transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	transition-duration: 0.3s;
}

.checkbox-animated-inline label .box {
	border: 2px solid #000;
	height: 20px;
	width: 20px;
	z-index: 888;
	-webkit-transition-delay: 0.2s;
	-moz-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.checkbox-animated-inline label .check {
	top: -7px;
	left: 6px;
	width: 12px;
	height: 24px;
	border: 2px solid #BADA55;
	border-top: none;
	border-left: none;
	opacity: 0;
	z-index: 888;
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	transform: rotate(180deg);
	-webkit-transition-delay: 0.3s;
	-moz-transition-delay: 0.3s;
	transition-delay: 0.3s;
}

.checkbox-animated-inline.checkbox-animated-inline {
	display: inline-block;
}

.checkbox-animated-inline.checkbox-animated-inline+.checkbox-animated-inline {
	margin-left: 10px;
}

.cargo-inner {
	margin: 45px 0;
}

.cargo-des h4 {
	font-size: 24px;
	font-weight: 500;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
}

.cargo-des p {
	font-size: 16px;
	color: #000;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
}

.cargo-des {
	padding: 20px 0;
}

p.table-bot-text {
	font-family: 'FSIndustrieNw-Regular', sans-serif;
}

.gsl-equi td {
	font-family: 'FSIndustrieNw-Regular', sans-serif;
}

.gsl-equi th {
	font-family: 'FSIndustrieNw-Regular', sans-serif;
}

.table-heading {
	width: 100%;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
	font-size: 26px !important;
	font-weight: 500;
}

.table-heading1 {
	width: 100%;
	text-align: left;
	padding-left: 15px;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
	font-size: 23px;
	margin-bottom: 0;
}

p.image-l-txt {
	font-family: 'FSIndustrieNw-Regular', sans-serif;
	padding-top: 10px;
}

.table-img {
	padding-bottom: 15px;
}

.eco-cal {
	margin-bottom: 0;
	margin-top: 24px;
}

.car-ser-img {
	text-align: center;
}

.car-ser-img img {
	width: 90%;
}

.org-chart-full {
	padding-top: 55px;
}

ul.orgg-chart li {
	padding: 7px 0;
	color: #001524;
}

.dropdown-submenu {
	position: relative;
}

.dropdown-submenu>.dropdown-menu {
	top: 0;
	left: 100%;
	margin-top: -6px;
	margin-left: -1px;
	-webkit-border-radius: 0 6px 6px 6px;
	-moz-border-radius: 0 6px 6px;
	border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
	display: block;
}

.dropdown-submenu>a:after {
	display: block;
	content: " ";
	float: right;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
	border-width: 5px 0 5px 5px;
	border-left-color: #ccc;
	margin-top: 5px;
	margin-right: -10px;
}

.dropdown-submenu:hover>a:after {
	border-left-color: #fff;
}

.dropdown-submenu.pull-left {
	float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
	left: -100%;
	margin-left: 10px;
	-webkit-border-radius: 6px 0 6px 6px;
	-moz-border-radius: 6px 0 6px 6px;
	border-radius: 6px 0 6px 6px;
}

.local-inf-back {}

.carco-track.local select {
	width: 100%;
	outline: none;
	padding: 8px 13px;
	background: rgb(234, 234, 234);
	border: 1px solid rgba(227, 227, 227, 0.4);
	margin-bottom: 12px;
}

ul.orgg-chart {
	list-style: none;
	padding: 0 0 0 20px;
}

.carco-track.local {
	position: relative;
}

.grey-btn {
	padding: 8px 8px !important;
	background: #123c60;
	border: 1px solid rgba(227, 227, 227, 0.76);
	outline: None;
	position: absolute;
	bottom: 12px;
	right: 0;
	color: #fff;
	width: 120px;
	border-radius: 0px !important;
	height: 42px;
}

ul.orgg-chart li a {
	padding: 7px 0;
	color: #001524;
}

.accordion-link {
	position: relative;
	color: #333;
	font-size: 14px;
	margin: 0px;
	padding: 16px 7px;
	cursor: pointer;
	background: #e7e7e7;
	border-bottom: 1px solid rgba(247, 247, 247, 0.4);
	box-sizing: border-box;
	text-transform: capitalize;
}

.accordion-link {
	position: relative;
	color: #fff;
	font-size: 15px;
	margin: 0px !important;
	;
	padding: 7px 16px !important;
	cursor: pointer;
	background: #123c60;
	border-bottom: 0px !important;
	box-sizing: border-box;
	text-transform: capitalize;
}

.accordion-toggle h2 {
	margin: 0px;
	padding: 10px 0;
	font-size: 21px;
}

.accordion-panel {
	padding: 10px;
	border-bottom: 1px solid #f7f7f7;
	background: #efefef;
}

.sche-title.carres h3 {
	font-size: 25px;
	width: 60%;
	margin: 15px auto;
	line-height: 40px;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
	font-family: 'Open Sans', sans-serif;
}

.enqiry-f {
	position: relative;
}

.enquiy-t {
	margin-top: 20px;
}

.enquiy-t p {
	display: flex;
}

.enquiy-t p i {
	font-size: 21px;
	margin-right: 10px;
	width: 20px;
	color: #b3561b;
}

.enquiy-t h2 {
	font-size: 21px;
	margin-bottom: 12px;
	font-weight: 500;
	color: #000;
}

.door-to-dor h3 {
	font-size: 23px;
}

.door-to-dor h4 {
	font-size: 18px;
	padding-top: 10px;
}

.door-to-dor ul {
	padding: 0 10px;
}

.solas h {
	font-size: 21px;
}

.enqs-in {
	width: 100%;
	background: #f5f9fc;
	border: none;
	padding: 10px 15px;
	color: #212121ba;
	font-size: 14px;
	margin-bottom: 4px;
}

.boxess label {
	display: inline-block;
	text-align: left;
	padding: 12px 24px;
	width: 49%;
}

.boxess label input {
	margin: 0 10px;
}

a.un-subs {
	padding: 10px 35px;
	margin-top: 8px;
	display: inline-block;
	font-size: 16px;
	color: #fff;
	border-radius: 18px;
}

.tick-option i {
	background: #fb8902;
	border-radius: 50%;
	color: #fff;
	padding: 0;
	font-size: 20px;
	width: 50px;
	height: 50px;
	line-height: 50px
}

.tick-option h4 {
	text-align: center;
	padding: 12px 0px;
}

.messages {
	font-size: 14px;
}

.closing {
	margin: 30px 0px 10px;
}

.closing a {
	background: #fb8902;
	border: none;
	color: #fff;
	padding: 9px 30px;
	text-transform: uppercase;
	font-weight: 600;
	text-decoration: None;
}

button.but-si-sub {
	width: 183px;
	padding: 10px 15px;
	margin: 20px 15px;
	height: 45px;
	border-radius: 19px;
	background: #fb8902;
	color: #fff;
	border: 1px solid #fff;
	text-align: center;
}

button.but-si-sub-saved {
	width: 183px;
	padding: 10px 15px;
	margin: 20px 15px;
	height: 45px;
	border-radius: 19px;
	background: #b16d1c;
	color: #fff;
	border: 1px solid #fff;
	text-align: center;
}

.closa-butts {
	text-align: right;
	display: block;
	color: #000;
	float: right;
	padding: 5px;
	border-radius: 50%;
	font-size: 12px;
}

.testiss-tt {
	text-align: left;
	padding: 50px 20px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.testiss-tt p.texttt {
	font-size: 18px;
	color: #3a3a3a;
}

.testimon {
	width: 300px;
	height: 300px;
	overflow: hidden;
	margin: 30px auto;
	border-radius: 50%;
	position: relative;
}

.slide.new-newss {
	margin-bottom: 19px;
	background: #f9874408;
}

.indus-immgg {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 130px;
	width: 130px;
	border-radius: 50%;
}

.slider2 .slide.slick-slide {
	padding: 0;
}

.slider2 {}

.feed-hi {
	position: fixed;
	right: -46px;
	z-index: 9999;
	background: #f98744;
	top: 350px;
	transform: rotate(270deg);
}

.slider2 ul.slick-dots li {
	background: #9c9c9c;
	height: 7px;
	width: 7px;
	border-radius: 50%;
	padding: 5px;
}

.slider2 ul.slick-dots li.slick-active {
	background: #f98744;
}

.feed-hi a {
	padding: 8px 30px;
	display: block;
	color: #fff;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
	font-weight: 500;
}

ul.sec-pot-check.new-pot-radio.feedbac-radd li {
	width: 100%;
}

ul.sec-pot-check.new-pot-radio.feedbac-radd {
	text-align: left;
	position: inherit;
	top: inherit;
	transform: inherit;
}

ul.sec-pot-check.new-pot-radio.feedbac-radd.yesno-only li {
	width: 47%;
	margin: 3px 0;
}

button.feeds-but {
	height: 35px;
	background: #fb8902;
	color: #fff;
	border: 1px solid #fff;
	cursor: pointer;
	margin-top: 30px;
	height: 45px;
	width: 190px;
	border-radius: 19px;
}

.testi-sec {
	padding: 60px 0;
	margin: 0 !important;
	background: #f5f9fc;
}

ul.gsl-off-cont-detail {
	padding: 0;
	margin: 20px 0;
	text-align: center;
}

ul.gsl-off-cont-detail li {
	display: block;
	width: 100%;
	margin: 0;
	color: #000;
	background: #fff;
	text-align: left;
}

li.caon-first ul li p {
	padding: 10px 10px;
	margin: 0;
	width: 49%;
	display: inline-block;
}

li.caon-second ul li p {
	padding: 10px 10px;
	margin: 0;
	width: 49%;
	display: inline-block;
}

li.caon-first ul li p.con-addrs-back {}

li.caon-second ul li p.con-addrs-back {}

p.con-addrs-back {}

li.caon-third ul li p {
	padding: 10px 10px;
	margin: 0;
	width: 49%;
	display: inline-block;
}

li.caon-first ul {
	display: inherit;
	padding: 0;
}

li.caon-second ul {
	display: inherit;
	padding: 0;
}

li.caon-third ul {
	display: inherit;
	padding: 0;
}

li.caon-first ul li,
li.caon-second ul li,
li.caon-third ul li {
	border-bottom: 1px solid rgba(0, 0, 0, 0.11);
}

ul.gsl-off-detail {
	padding: 0;
	margin: 20px 0;
}

ul.gsl-off-detail.contact-det-off {
	padding: 4px 0;
	background: #f7f7f721;
	margin: 0 0 20px 0;
	text-align: left;
	display: flex;
	flex-direction: row;
}

ul.gsl-off-detail li {
	display: inline-block;
	width: 49.8%;
	margin: 0px 0;
	color: #000;
	background: #f7f7f7;
	padding: 14px 10px;
	border-bottom: 1px solid #00000042;
}

ul.gsl-off-detail ul li {
	width: 100%;
	margin: 0px 0;
	color: #000;
	background: #fdfdfd;
	padding: 9px 10px;
	height: 58px;
	border-bottom: 1px solid rgba(97, 97, 97, 0.12);
}

ul.gsl-off-detail ul li.u-head-bor {
	border: 1px solid rgb(255, 255, 255);
	background: #d8d8d8;
}

ul.gsl-off-detail ul {
	display: inline-block;
	width: 100%;
	padding: 0px;
	text-align: center;
}

ul.factadfi {
	list-style: none;
	padding: 0;
}

ul.factadfi li {
	width: 100%;
	display: inline-block;
	margin: 8px 0;
	color: #000;
	background: rgba(206, 206, 206, 0.07);
	padding: 10px 12px;
	display: inline-block;
	text-align: left;
	border-bottom: 1px solid rgb(162, 162, 162);
}

.loader {
	border: 3px solid #dedede;
	border-radius: 50%;
	border-top: 4px solid #f98744;
	width: 30px;
	height: 30px;
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
	float: left;
	margin-right: 5px;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

ul.factadfi li a {
	width: 40%;
	display: inline-block;
	padding: 0;
	height: 90px;
	overflow: hidden;
}

a.heading-facts-de {
	padding: 0;
}

ul.factadfi li a i {
	font-size: 20px;
	color: #383838;
}

ul.factadfi li a b {
	margin-left: 5px;
}

.gsl-off-det {
	margin-bottom: 30px;
}

.eco-full h4 {
	font-size: 18px;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
}

ul.ecom-page li {
	padding: 10px 0;
}

ul.e-com-logo {
	list-style: none;
	padding: 0;
	text-align: center;
}

ul.e-com-logo li {
	display: inline-block;
	margin: 0 10px;
}

form.con-ser {
	position: relative;
}

form.con-ser input {
	width: 95%;
	border: 1px solid #00000014;
	padding: 5px 10px;
}

form.con-ser button {
	position: absolute;
	right: 1px;
	top: 1px;
	padding: 5px 10px;
	background: rgb(143, 143, 143);
	border: none;
	font-family: 'Muli', sans-serif;
	font-size: 14px;
	color: #fff;
}

.phone-country div.code-phoness {
	width: 27%;
}

.phone-country div.code-phoness input {
	width: 65% !important;
}

.phone-country div.cod-ph-noss input {
	width: 92%;
}

.cod-ph-noss {
	width: 100%;
}

.regulat-image {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.news-in-page.regulat-text a {
	background: rgb(224, 132, 58);
	color: #fff;
	padding: 6px 11px;
}

.regulat-text p {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.news-in-page.regulat-text {
	margin: 65px 0;
}

.reg-rowss {
	border-bottom: 1px solid rgb(224, 132, 58);
	background: rgb(255, 250, 246);
	margin-bottom: 10px !important;
}

.under-cons h4 {
	text-align: center;
	font-size: 23px;
}

.under-cons {
	text-align: center;
}

.under-cons p a {
	font-size: 18px;
	margin-left: 5px;
}

.under-cons p {
	color: #020202;
	margin-top: 36px;
	font-size: 18px;
}

.g-recaptcha div {
	margin: 20px auto 0;
	width: 100% !important;
}

.news-imggs img {
	width: 100%;
}

.news-rt-text {
	padding: 12px 10px;
}

.news-in-page {
	border-bottom: 2px solid #ca7a35;
}

.news-imggs {
	height: 300px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.news-rt-text h3 {
	font-size: 21px;
	font-family: 'Muli', sans-serif;
	font-weight: 600;
}

.news-rt-text p {
	font-family: 'Muli', sans-serif;
}

p.date-news {
	font-size: 16px;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
}

.data-protect {
	height: 300px;
	width: 300px;
	margin: 0 auto;
	border-radius: 50%;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	position: absolute;
	margin: 0 auto;
	text-align: center;
	left: 0;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}

section.inner-center-data {
	margin: 80px 0;
}

.dat-bacc {
	padding: 50px 0;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
}

.dat-color {
	background: #cd79342b;
	padding: 50px 0;
}

.dat-relat {
	position: relative;
}

.acc-pannel-foot .accordion-panel {
	padding: 15px;
}

form.abt-full {}

.abt-text-con {
	padding: 20px 20px;
}

.abt-text-con img {
	width: 100px;
}

.abt-text-con h3 {
	margin-top: 20px;
	font-size: 40px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	position: relative;
	margin-bottom: 40px;
}

.abt-text-con h3:after {
	content: '';
	position: absolute;
	top: 55px;
	width: 40px;
	height: 4px;

	text-align: left;
	left: 0;
	right: 0;
}

.abt-text-con p {
	font-size: 16px;
	color: #001524;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	height: 95px;
}

.abt-gsl-img {
	height: 400px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.abt-gsl-but {
	background: #123c60;
	border: none;
	color: #fff;
	font-size: 14px;
	padding: 7px 15px;
	border: 1px solid #123c60;
	transition: all .5s ease-in;
}

.our-mis-pad {
	margin-top: 35px;
}

.wware-imggg {
	height: 300px;
}

.s-full-form {
	box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .28);
}

a.abt-gsl-but:hover {
	background: #fff;
	color: #123c60;
	border: 1px solid #123c60;
	transition: all .5s ease-in;
}

.who-wee {
	padding: 100px 90px 40px;
}

.who-wee-image {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

.sche-title.serv-secion-on h3 {
	color: #000;
}

.sche-title.serv-secion-on {
	padding: 0;
}

.cargo-ser-p p {
	font-size: 18px;
	border-left: 3px solid #f48b43;
	padding-left: 10px;
}

.car-ser-new h3 img {
	width: 100px;
	margin: 0px 15px 45px 0px;
	padding: 0 0 18px 0;
}

.car-ser-new h3 {
	font-size: 40px;

	font-weight: 100;
}

.car-ser-new {
	padding: 0 10px;
}

.cargo-n-imgg img {
	width: 100%;
	position: absolute;
	top: 60%;
	transform: translateY(-50%);
	padding-right: 20px;
}

.dout-inner {
	border-left: 7px solid #fdc200;
	padding: 0 0px 40px 15px;
}

.dout-inner h3 {
	font-size: 41px;
	font-weight: 600;
	line-height: inherit;
	margin: 0;
	color: #123c60;
}

select.con-selet-#ca4d36 {
	padding: 5px 10px;
	width: 100%;
	border: 1px solid rgb(191, 191, 191);
	border-color: rgba(144, 144, 144, 0.5411764705882353);
	border-width: 1px;
	font-size: 14px;
}

.feedback-code {
	position: relative;
	width: 20%;
}

.feedback-code label.pl-feed {
	position: absolute;
	width: auto;
	left: 2px;
	top: 1px;
}

.feedback-code input.contry1 {
	width: 100% !important;
	padding-left: 30px;
}

.feedback-number label.min-feed {
	position: absolute;
	width: auto;
	left: 10px;
	top: 1px;
}

.feedback-number {
	position: relative;
	width: 80%;
	margin-left: 5px;
}

.feedback-number input {
	padding-left: 30px;
}

.recent-n-inner a i {
	font-size: 12px;
	background: #0068a6;
	width: 25px;
	border-radius: 50%;
	height: 25px;
	color: #fff;
	text-align: center;
	line-height: 25px;
}

.heading-box {
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 3px;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
}

.info-schedule {
	font-size: 13px;
	font-weight: 600;
}

.banner-tabss.banner-tabss1 ul.nav.nav-tabs.ban-tab-ul li.nav-item a.nav-link.active {
	background: #f88240;
	border: 2px solid #fff;
	color: #fff;
	transition: all .5s ease-out;
}

.banner-tabss.banner-tabss1 .tab-content.ban-tab-cont {
	height: auto;
	position: relative;
	margin: 80px 0 40px;
	display: inline-block;
	width: 100%;
}

.banner-tabss.banner-tabss1 .question i {
	color: #f1f1f1;
	background: #f88240;
}

.banner-tabss.banner-tabss1 {
	background: #f1f1f1;
}

.banner-fin1 {
	position: relative;
	margin-top: 103px;
}

.banner-tabss.banner-tabss1 ul.nav.nav-tabs.ban-tab-ul li.nav-item a.nav-link.active {
	background: #0a77b9;
	border: 2px solid #fff;
	color: #fff;
	transition: all .5s ease-out;
}

.banner-tabss.banner-tabss1 .tab-content.ban-tab-cont {
	height: auto;
	position: relative;
	margin: 40px 0 0px;
	display: inline-block;
	width: 100%;
}

.banner-tabss.banner-tabss1 .question i {
	color: #fb8902;
	background: #ffffff;
}

.carco-track {
	width: 500px;
}

.flag_login_base {
	border-left: 1px solid #3a3a3a;
}

.flag_login_base .login_gsl_img {

	width: 100px !important;
	padding-left: 5px;

}

.flag_login_base .flag-sec-respons {
	border-left: none !important;
	padding-left: 0px !important;
}

.tool-services {
	padding: 13px;
	background-color: white;
	box-shadow: 0px 2px 3px #a0a0a0;
	margin-top: -99px;
	min-height: 210px;
	border-radius: 3px 20px;
}

.sch-full-form-btn a.btn,
.directional-arrow a.btn {
	border-radius: 0.25rem;
	border: 1px solid transparent;
	padding: 0.5rem 1rem;
	color: #fff;
	font-size: 12px;
	background: #123c60;
}

.sch-tb-row-head th {
	position: sticky;
	top: -1px;
	background: #123c60;
	color: #FFF;
	padding-right: 55px;
	padding-top: 10px;
}

.sch-tb-row-head th p {
	width: 150px;
	font-size: 16px;
	font-weight: 500;
	padding-left: 10px;
	white-space: nowrap !important;
}

.sch-line-tb-form .table-responsive {
	max-width: 75em;
	max-height: 35em;
	overflow: scroll;
	position: relative;
}

tbody.sch-tb-row-body td p {
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 1px;
	padding: 3px;
}

tbody.sch-tb-row-body td:nth-child(odd) {
	background: #ffe0ce;
}

tbody.sch-tb-row-body td:nth-child(1) {
	background: #e9ecef;
}

.voyage_details_inner {
	background: #EEEEEE;
	margin-bottom: 25px;
	padding: 25px;
}

.sch-line-tb-form .voyage_details_base {
	margin-top: 50px;
}

.tariff-form-th tr th {
	text-align: left;
	padding: 15px;
	font-size: 16px;
	background: #123c60;
	color: white;
	white-space: nowrap;
}

.tariff-form-th tr .tar-colap-cont {
	padding: 0 !important;
}

tr.final-row {
	background: #ffe787;
}

tr.min-row td {
	padding: 10px 3px;
	font-size: 15px;
}

.map-card-heading.tariff-ref-head {
	padding: 0px 14px;
	background: none !important;
	color: #2196F3 !important;
}

.card-header {
	border-bottom: 0 !important;

}

.tariff-form-cont .accordion {
	border: 0px !important;
}

p.tariff-print a.btn,
button.ptp-btn {
	border-radius: 0.25rem;
	border: 1px solid transparent;
	padding: 0.5rem 1rem;
	color: #fff;
	font-size: 12px;
	background: #123c60;
}

p.tariff-print a:hover.btn,
button.ptp-btn:hover {
	color: #fff !important;

}

p.tariff-print-para {
	padding: 20px;
	padding-bottom: 0;
}

.track-shipment-output-tb1 {
	background: #123c60 !important;
	color: #fff;
}

.card.p-3.main-cargo-head-container {
	background-color: #f9f9f9;
	border-color: #123c60;
	min-height: 140px;
}

.table1 tr td {
	font-size: 15px !important;
	border: 0px;
	padding: 8px;
	display: inline-block;
}

.table-font-weight {
	font-weight: bold;
	color: #13406f;
}

table.table.track-shipment-output-tb2 tr th {
	text-align: left;
	padding: 15px;
	font-size: 15px;
	background: #123c60;
	color: white;
}

.track-shipment-output-tb2-tr td:nth-child(1) {

	color: #2196F3;
}

thead.thead-dark th {
	text-align: left;
	padding: 15px;
	font-size: 21px;
	background: #123c60;
	color: white;
	white-space: nowrap;
}

tr.border_bottom td {
	border-bottom: 1px solid black;
	padding: 5px;
	font-size: 27px;
	font-weight: 400;
	padding-left: 15px;
}

.row.tare-pdf-btn a.btn {
	border-radius: 0.25rem;
	border: 1px solid transparent;
	padding: 0.5rem 1rem;
	color: #fff;
	margin-left: 14px;
	font-size: 12px;
	background: #123c60;
}

thead.thead-dark.ptp-tb-head tr th {
	border: 0.2px solid #c3c3c3;
}

tr.point-to-point-even td,
tr.point-to-point-odd td {
	border: 0.2px solid #c3c3c3;
	text-align: center;
}

tr.point-to-point-even td {
	background-color: #ffe2d1;
}

@media (max-width:767px) {
	li.dropdown.dropdown-hovers:hover ul.dropdown-menu {
		max-height: 420px;

	}

	ul.dropdown-menu.menu-help-bg {
		left: -130px;
	}

	ul.banner-in-tablist li {
		margin: 0 0px !important;
	}

	ul.dropdown-menu.menu-help-bg.knowledge-list {
		left: -50px !important;
		top: 35px;
	}

	.ind-tab-mab {
		position: inherit !important;
		left: inherit;
		top: inherit;
	}

	ul.schedu-in-page li a {
		display: block;
		margin: 10px 0;
	}

	ul.schedu-in-page li {
		display: inherit;
	}

	.menu-image-drop {
		background-position: right top;
		background-size: 20%;
		background-repeat: no-repeat;
		margin: 6px 0px;
		opacity: 0;
		position: absolute;
		top: 0;
		height: 100px;
	}

	li.dropdown.dropdown-hovers:hover ul.dropdown-menu {
		max-height: 420px;
		overflow: scroll;
	}

	li.dropdown.dropdown-hovers ul.dropdown-menu {
		max-height: 0px;
	}

	li.dropdown.dropdown-hovers ul.dropdown-menu.main-menu {
		min-width: 300px !important;
	}

	li.dropdown.dropdown-hovers:hover ul.dropdown-menu.main-menu {
		min-width: 300px !important;
	}

	nav.head-navbar {
		padding: 20px 20px 20px 20px;
	}

	.head-navbar a img {
		width: 180px;
	}

	.slider ul.slick-dots {
		bottom: 40px;
		right: 0;
		left: 0;
		width: auto;
		transform: inherit;
		top: inherit;
		display: inherit;
		text-align: center;
		margin: 0 auto;
		width: 100%;
	}

	#clip {
		font-size: 55px;
		font-weight: bolder;
		text-align: center;
	}

	ul.ser-f-dropdown {
		padding: 0;
		text-align: center;
	}

	.slider ul.slick-dots li {
		display: inline-block;
	}

	.sec-serv h4 {
		text-align: center;
		margin: 0 0 23px 0;
		position: relative;
		top: inherit;
		transform: inherit;
	}

	div ul.sec-portal {
		margin: 0 auto;
		text-align: center;
		display: block;
	}

	ul.sec-pot-check {
		position: inherit;
		top: inherit;
		transform: inherit;
		padding: 0;
		margin: 15px 0 0 0;
	}

	ul.sec-portal li {
		width: 100%;
	}

	ul.sec-pot-check.home-port {
		position: inherit;
		top: inherit;
		transform: inherit;
		padding: 0;
		text-align: center;
	}

	.solor-section {
		margin: 10px 0;
	}

	.solos-text {
		background: #fffefc;
	}

	.solos-text h4 {
		font-size: 19px;
	}

	button.portal-searc {
		margin-top: 15px;
	}

	ul.sec-portal li input {
		width: 250px;
		margin-bottom: 10px;
	}

	ul.sec-portal li select {
		width: 100%;
	}

	ul.countr-seperate li {
		padding: 18px 20px;
		width: 49.5%;
		text-align: left;
	}

	.serv-inner-full p {
		padding: 0 1px;
		color: #fff;
	}

	.accordion-panel.map-panel-inn {
		height: 400px;
	}

	.accordion-panel.in-map-list {
		height: 450px;
	}

	.map-container {
		height: 500px;
	}

	.gsl-rit-enn {
		position: absolute;
		left: 0;
		bottom: 0;
		height: 150px;
		top: 500px;
	}

	.form-right {
		width: 100%;
		padding: 20px;
		font-family: 'Open Sans', sans-serif;
	}

	ul.footer-list-quk {
		padding: 0;
	}

	#clip2,
	#clip1 {
		font-size: 55px;
	}

	.foot-cop-ang {
		text-align: center;
	}

	ul.footer-social {
		padding: 0;
		text-align: center;
	}

	ul.contact-form-eq li {
		display: block;
		width: 100%;
	}

	ul.countr-seperate {
		padding: 110px 0 80px;
	}

	.form-head h2,
	.recent-headd h2 {
		font-size: 30px;
	}

	.footer-subs {
		position: relative;
		text-align: left;
	}

	.footer-subs input {
		width: 100%;
	}

	.footer-subs button {
		padding: 9px 10px;
		right: 0;
		bottom: 0;
	}

	.menu-respons-but {
		right: 18px;
		position: absolute;
		top: 10px;
		background: #fff;
	}

	.navbar-open.collapse.show {
		display: block;
		z-index: 1;
	}

	.navbar-open.collapse.show ul li {
		padding: 0px;
		z-index: 1;
	}

	.flag-sec-respons {
		position: absolute;
		top: 23px;
		right: 75px;
		text-align: right;
		display: table;
	}

	ul.flag-sec-respons li {
		position: relative;
		display: inline-block;
	}

	select.menu-countyy {
		width: 94px;
	}

	#ban-article ul.banner-in-tablist li {
		margin: 0 0px;
		width: 15%;
	}

	ul.nav.nav-tabs.ban-tab-ul.banner-in-tablist {
		width: 100%;
		margin-top: 20px;
	}

	ul.banner-in-tablist li a {
		width: 60px !important;
		height: 60px !important;
	}

	ul.nav.nav-tabs.ban-tab-ul li.nav-item {
		width: 13%;
		display: inline-block;
	}

	ul.nav.nav-tabs.ban-tab-ul li.nav-item a.nav-link {
		width: 70px;
		height: 70px;
	}

	.tab-iconshov h2 {
		font-size: 12px;
	}

	.tab-iconshov p {
		font-size: 12px;
	}

	.tab-icons img {
		width: 25px;
	}

	.tabcon-input input {
		width: 100%;
	}

	div.tabcon-input {
		width: 100%;
	}

	.tabcon-input select {
		width: 100%;
	}

	.tabcon-input button {
		position: absolute;
		top: -16px;
		right: -8px;
	}

	.tab-content.ban-tab-cont {
		margin: 0px 45px;
		height: 50px;
	}

	form.point-to {
		padding: 0;
		margin: 0;
		box-shadow: none;
	}

	.form-fback {
		padding: 40px 0;
	}

	.pot-form {
		padding: 0 !important;
	}

	ul.sec-pot-check.new-pot-radio li {
		width: 48%;
		margin: 0;
	}

	.inn-sche-list a img {
		width: 28px;
		margin-right: 12px;
	}

	.inn-sche-list a {
		font-size: 15px;
	}

	.sche-title h3 {
		font-size: 26px;
	}

	.service-con-full-1,
	.service-con-full-2,
	.service-con-full-3,
	.service-con-full-4 {
		opacity: 1;
		background-attachment: fixed;
	}

	.regulat-image {
		height: 300px;
	}

	ul.factadfi li a {
		width: 100%;
		display: block;
		padding: 25px 0px;
	}

	.enquiy-t {
		position: inherit;
		top: inherit;
		transform: inherit;
	}

	.who-wee-image {
		height: 300px;
	}

	.who-wee {
		padding: 40px 0 40px;
	}

	.abt-text-con {
		padding: 20px 0px;
	}

	ul.gsl-off-detail.contact-det-off {
		display: block;
	}

	li.caon-first ul,
	li.caon-second ul,
	li.caon-third ul {
		display: block;
		padding: 0;
	}

	.cargo-n-imgg img {
		position: relative;
		top: inherit;
		transform: inherit;
		padding: 0 !important;
		margin: 0;
	}

	.dtod-res {
		display: flex;
		flex-direction: column-reverse;
	}

	.phone-country div.cod-ph-noss input {
		width: 89%;
	}

	.phone-country div.code-phoness {
		width: 40%;
	}

	video.banner-video {
		margin-top: 30px;
	}

	.dout-inner {
		margin-bottom: 15px;
	}

	ul.dropdown-menu.main-menu:before {
		display: none;
	}

	.menu-full-pop {
		background: #fff;
		background-image: none !important;
	}

	.data-protect {
		position: inherit;
		margin: 0 auto;
		text-align: center;
		top: inherit;
		transform: inherit;
	}

	.car-ser-new h3 {
		font-size: 26px;
		margin-bottom: 5px;
		font-weight: 100;
	}

	.car-ser-new h3 img {
		width: 80px;
		margin: 0 0 28px 0;
		padding: 0 0 0 0;
	}

	.pjt-cargo-row {
		display: flex;
		flex-direction: column-reverse;
	}

	ul.sec-pot-check.new-pot-radio.feedbac-radd.yesno-only li {
		width: 100%;
	}

	#enquire .form-inputs {
		padding: 0 0px;
	}

	ul.trade-tab-li li {
		width: 100%;
	}

	li.caon-first ul li p,
	li.caon-second ul li p,
	li.caon-third ul li p {
		width: 100%;
	}

	.s-full-form {
		box-shadow: none;
	}

	.fixed-bottom,
	.fixed-top {
		position: inherit;
	}

	.banner-fin1 {
		position: relative;
		margin-top: 20px;
	}

	.banner-aset.slick-slide {
		height: 400px;
	}

	.ship_ppp,
	.ship_pp {
		margin-right: 15px;
		display: block;
		text-align: center;
	}

	.banner-tabss.banner-tabss1 {
		background-size: cover;
	}

	.flag_login_base {
		border-left: none !important;
		border-top: 1px solid #eae5e5;
		text-align: center;
		width: 100%;
		padding: 14px;
	}

	.footer-section .logo_img_center img {
		max-width: 250px;
	}

	.rr-mobile-custom-icon {
		display: block;
	}

	.rr-mobile-icon {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		text-align: center;
		justify-content: space-between;
		padding: 0px;
		background: #f8f8f8;
	}

	#header {
		border-bottom: 0px rgba(0, 0, 0, 0.07) solid;
	}

	.rr-mobile-icon li {
		list-style: none;
		flex: auto;
		border-right: 1px solid #e3e3e3;

	}


	.rr-mobile-icon li:last-child {
		border: none;
	}

	.inner-container .inner-container-section-1 p {
		color: #fff !important;
	}

	.tools-card .btn {
		padding: 5px;
	}

	.tools-card .card-data {
		max-width: 100% !important;
		margin: 0;
	}

	.inner-card-detail .card-icon {
		width: 30px;
		height: 30px;
		object-fit: contain;
		transform: rotate(90deg);
		transition: all .5s;
		margin-top: 0;
	}

	.tools-card .card-item {
		background: none;
		padding: 0px 6px !important;
	}

	.tools-card .card-item .card-desktop-view {
		background: #e3e3e3;
		padding-top: 10px;
	}

	.tools-card .card-header {
		background: none !important;
	}




}

@media (min-width:768px) and (max-width:992px) {
	div.tabcon-input {
		width: 70%;
	}

	ul.dropdown-menu.main-menu:before {
		display: none;
	}

	ul.schedu-in-page li a {
		width: 100%;
		display: block;
	}

	ul.schedu-in-page li {
		margin: 10px 14px;
		width: 34%;
	}

	.who-wee-image {
		height: 300px;
	}

	.who-wee {
		padding: 40px 0 40px;
	}

	.abt-text-con {
		padding: 20px 0px;
	}

	li.dropdown.dropdown-hovers:hover ul.dropdown-menu {
		max-height: 340px;
	}

	li.dropdown.dropdown-hovers ul.dropdown-menu {
		max-height: 0px;
	}

	.sec-serv h4 {
		text-align: center;
		position: relative;
		top: inherit;
		transform: inherit;
	}

	ul.sec-portal {
		margin: 20px 0 20px 0;
		text-align: center;
	}

	ul.factadfi li a {
		width: 100%;
		display: block;
		padding: 25px 0px;
	}

	ul.sec-portal li input {
		width: 250px;
		margin-right: 5px;
		margin-bottom: 5px;
	}

	ul.sec-portal li select {
		width: 100%;
	}

	ul.sec-pot-check.home-port {
		position: relative;
		top: 40%;
		transform: translateY(-50%);
		padding: 0;
		text-align: center;
	}

	ul.sec-pot-check {
		list-style: none;
		text-align: left;
		padding: 0;
		margin: 14px 0;
		font-weight: 100;
		font-family: 'FSIndustrieNw-Regular', sans-serif;
	}

	ul.contact-form-eq li {
		width: 100%;
	}

	.solos-text p {
		font-size: 15px;
		text-align: center;
		padding: 12px 0px;
		line-height: 18px;
		margin-bottom: 0;
	}

	.solos-text h4 {
		font-size: 20px;
		font-weight: 600;
		text-transform: uppercase;
		padding-bottom: -4px;
		position: relative;
		color: #f98744;
	}

	.solos-text h4 {
		font-size: 20px;
	}

	.solos-text {
		height: 180px;
		text-align: center;
		padding: 12px 15px 0 15px;
		position: relative;
	}

	.footer-subs input {
		width: 100%;
	}

	.footer-subs button {
		right: 0;
		top: 39px;
	}

	.service-dr-1 button,
	.service-dr-2 button,
	.service-dr-3 button {
		width: 190px;
		font-size: 14px;
	}

	#clip {
		text-align: center;
		margin-left: 117px;
		margin-bottom: -2px;
	}

	ul.nav.nav-tabs.ban-tab-ul li.nav-item {
		width: 10%;
	}

	ul.banner-in-tablist li {
		margin: 20px !important;
	}

	.head-navbar a img {
		width: 250px;
	}

	.head-navbar {
		padding: 10px;
	}

	.menu-respons-but {
		right: 18px;
		position: absolute;
		top: 20px;
		background: #fff;
	}

	.navbar-open.collapse.show {
		display: block;
	}

	.navbar-open.collapse.show ul li {
		padding: 10px 10px;
		z-index: 1;
	}

	.menu-respons-but {
		top: 14px;
		padding: 5px;
	}

	.flag-sec-respons {
		flex-direction: row !important;
	}

	ul.sec-pot-check.new-pot-radio li {
		width: 40%;
	}

	.sol-regus {
		margin: 8px 0px;
	}

	ul.trade-tab-li.ind-tab-mab li {
		width: 25%;
	}

	ul.trade-tab-li.ind-tab-mab li a {
		height: 115px;
	}

	.regulat-image {
		height: 300px;
	}

	.enquiy-t {
		position: inherit;
		top: inherit;
		transform: inherit;
	}

	.cargo-n-imgg img {
		position: relative;
		top: inherit;
		transform: inherit;
	}

	.phone-country div.cod-ph-noss input {
		width: 85%;
	}

	.phone-country div.code-phoness {
		width: 55%;
	}

	div.ind-tab-mab ul li {
		width: 48.9%;
	}

	.dout-inner {
		margin-bottom: 15px;
	}

	.data-protect {
		position: inherit;
		margin: 0 auto;
		text-align: center;
		top: inherit;
		transform: inherit;
	}

	#enquire .form-inputs {
		padding: 0 0px;
	}

	ul.trade-tab-li li {
		width: 49.7%;
	}

}

@media (min-width:993px) and (max-width:1200px) {
	.head-navbar a img {
		width: 250px;
	}

	nav.head-navbar {
		padding: 10px;
	}

	.flag-sec-respons {
		margin: 0px 10px 0px 10px !important;
	}

	select.menu-countyy {
		font-size: 12px !important;
		margin: 1px;
	}

	.dropdown-toggle.menu-heads {
		font-size: 14px !important;
	}

	a.dropdown-toggle.menu-heads {
		padding: 0 5px;
	}

	li.dropdown.dropdown-hovers ul.dropdown-menu.main-menu {
		min-width: 250px !important;
	}

	li.dropdown.dropdown-hovers:hover ul.dropdown-menu.main-menu {
		left: -129px;
	}

	li.dropdown.dropdown-hovers ul.dropdown-menu.main-menu {
		left: -230px;
	}

	ul.sec-pot-check.new-pot-radio li {
		width: 25%;
	}

	ul.sec-portal li select,
	ul.sec-portal li input {
		width: 100%;
	}

	button.portal-searc {
		width: 100px;
	}

	ul.sec-pot-check li {
		width: 24%;
	}

	.radio-nane {
		font-size: 14px;
	}

	.serv-inner-full p {
		padding: 0 15px;
	}

	.solos-text {
		height: 240px;
	}

	.solos-text p {
		padding: 12px 10px;
	}

	.sol-regus {
		margin: 10px 0px;
	}

	ul.trade-tab-li.ind-tab-mab li {
		width: 25%;
	}

	.indus-immgg {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		height: 120px;
		width: 120px;
		border-radius: 50%;
	}

	ul.trade-tab-li.ind-tab-mab li a {
		height: 115px;
	}

	.gsl-rit-enn {
		position: relative;
		left: 0;
	}

	.phone-country div.code-phoness {
		width: 35%;
	}

	.phone-country div.cod-ph-noss input {
		width: 90%;
	}

}

@media (min-width:320px) and (max-width:480px) {
	ul.banner-in-tablist li {
		margin: 0 5px !important;
	}

	section.door-solas {
		margin: 0;
	}

	.tab-iconshov p {
		font-size: 12px;
	}

	.form-head h2,
	.recent-headd h2 {
		font-size: 21;
	}

	div#map {
		height: 400px;
	}

	.form-head h2 {
		margin-bottom: 45px;
	}

	.form-head h2:after {
		top: 100px;
	}

	.phone-country div.code-phoness {
		width: 70%;
	}

	.phone-country div.cod-ph-noss input {
		width: 80%;
	}

	.gsl-rit-enn {
		position: absolute;
		left: 0;
		z-index: 1059;
		width: 100%;
		bottom: 0;
		height: 150px;
		top: 300px;
	}

	div.solor-section {
		margin: 5px 0;
	}

	.menu-respons-but {
		font-size: 10px;
	}

	nav.head-navbar {
		padding: 15px 10px 5px 15px;
	}

	.container-new {
		width: 100% !important;
	}

	.flag-sec-respons {
		top: 0;
		right: inherit;
		width: 80%;
		left: 0px;
		text-align: center;
		position: relative;
		margin: 0 auto !important;
	}

	article.banner-tabss {
		position: relative;
		top: 0px;
		transform: inherit;
		padding: 1px 0 30px;
		background-image: url(./assets/bg_wave.png);
	}

	.banner-aset.slick-slide {
		height: auto;
		padding: 80px 0;
	}

	ul.nav.nav-tabs.ban-tab-ul li.nav-item {
		width: 20% !important;
		margin: 0 5px !important;
	}

	ul.nav.nav-tabs.ban-tab-ul li.nav-item a.nav-link {
		width: 60px;
		height: 60px;
	}

	.tab-icons img {
		width: 25px;
	}

	.tab-iconshov h2 {
		font-size: 10px;
	}

	.tabcon-input select {
		width: 93%;
		padding: 10px;
		border: 1px solid #0000002e;
		box-shadow: 0px 0px 11px 1px rgba(8, 8, 8, 0.24);
	}

	ul.nav.nav-tabs.ban-tab-ul {
		margin-top: 5px;
	}

	.tab-content.ban-tab-cont {
		height: 50px;
		position: relative;
		margin: 0px 0 5px !important;
	}

	.slider ul.slick-dots {
		display: none !important;
	}

	.slider2 {}

	.tabcon-input input {
		background: #fff;
		width: 93%;
		padding: 10px;
		font-size: 14px;
		border: 1px solid #0000002e;
		box-shadow: 0px 0px 11px 1px rgba(8, 8, 8, 0.24);
	}

	.tabcon-input button img {
		width: 40px;
	}

	div.tabcon-input button {
		width: 85px;
		height: 85px;
		right: 8px;
	}

	.head-navbar a img {
		width: 150px;
	}

	.inn-sche-list a img {
		width: 25px;
		margin-right: 10px;
	}

	.inn-sche-list a {
		width: 100%;
		font-size: 15px;
	}

	.banner-inside h2 {
		font-size: 21px;
	}

	.sche-title h3 {
		font-size: 21px;
	}

	.checkbox-animated label {
		padding-left: 35px;
		font-size: 14px;
	}

	.banner-video {
		margin-top: 28px;
	}

	.accordion-panel.map-panel-inn {
		height: 200px;
	}

	.accordion-panel.in-map-list {
		height: 250px;
	}

	.map-container {
		height: 300px;
	}

	.map-container-new {
		height: 408px !important;
	}

	.car-ser-new h3 {
		font-size: 20px;
		margin-bottom: 10px;
		font-weight: 100;
		text-align: center;
	}

	.car-ser-new h3 img {
		width: 70px;
		margin: 10px auto;
		padding: 0 0 0 0;
		display: block;
		text-align: center;
	}

	article.banner-tabss {
		padding: 30px 0 30px;
	}

	.map-card-container .card-body {
		height: 130px !important;
	}
}

@media(min-width:425px) and (max-width:767px) {
	article.banner-tabss {
		padding: 30px 0 30px;
	}

	.ind-tab-mab ul li a.active {
		background: #5cd0e1 !important;
		font-size: 16px;
	}

	.trade-tab-li li a {
		font-size: 13px;
	}

	ul.nav.nav-tabs.ban-tab-ul li.nav-item {
		margin: 0px 16px;
	}

	.head-navbar {
		padding: 10px;
	}

	.ind-tab-mab ul li {
		background: #84cf95 !important;
		width: 49.4%;
		text-align: center;
	}
}

.flag-sec-respons {
	margin: 0px 5px 0px 5px;
}

.flags-dr {
	display: none;
}

.others-links li {
	margin-bottom: 10px !important;
}

.timeline {
	position: relative;
	max-width: 1200px;
	margin: 0 auto;
}

.timeline::after {
	content: '';
	position: absolute;
	width: 6px;
	background-color: rgb(21 65 112);
	top: 0;
	bottom: 0;
	left: 50%;
	margin-left: 0px;
}

.times-con {
	padding: 10px 40px;
	position: relative;
	background-color: inherit;
	width: 50%;
}

.times-con::after {
	content: '';
	position: absolute;
	width: 25px;
	height: 25px;
	right: -15px;
	background-color: white;
	border: 4px solid #123c60;
	top: 15px;
	border-radius: 50%;
	z-index: 1;
}

.left-time {
	left: 0;
}

.right-times {
	left: 50%;
}

.left-time::before {
	content: " ";
	height: 0;
	position: absolute;
	top: 22px;
	width: 0;
	z-index: 1;
	right: 30px;
	border: medium solid white;
	border-width: 10px 0 10px 10px;
	border-color: transparent transparent transparent white;
}

.right-times::before {
	content: " ";
	height: 0;
	position: absolute;
	top: 22px;
	width: 0;
	z-index: 1;
	left: 30px;
	border: medium solid white;
	border-width: 10px 10px 10px 0;
	border-color: transparent white transparent transparent;
}

.right-times::after {
	left: -10px;
}

.content-timel {
	padding: 20px 30px;
	background-color: rgb(255 159 85 / 13%);
	position: relative;
	border-radius: 6px;
	border: 1px solid rgb(253 206 4);
}

.content-timel p {
	font-size: 16px;
	font-family: 'Open Sans', sans-serif;
}

.content-timel h3 {
	font-size: 20px;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
}

.content-timel h2 {
	font-size: 26px;
	font-family: 'FSIndustrieNw-Regular', sans-serif;
}

.inner-container .inner-container-section-1 h6 {
	text-transform: initial;
	font-size: 34px;
	color: #123C60;
	font-weight: bold;
	padding: 7px 0px;
	font-family: 'FSIndustrieNw', sans-serif;
	text-transform: uppercase;
}

.container-fluid.inner-container {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 380px;
	padding: 0px;
}

.inner-container .inner-container-section-1 p {
	font-size: 16px;
	line-height: 25px;
	color: #123C60;
	max-width: 390px;
}

@media screen and (max-width:600px) {
	.timeline::after {
		left: 31px;
	}

	.times-con {
		width: 100%;
		padding-left: 70px;
		padding-right: 25px;
	}

	.times-con::before {
		left: 60px;
		border: medium solid white;
		border-width: 10px 10px 10px 0;
		border-color: transparent white transparent transparent;
	}

	.left-time::after,
	.right-times::after {
		left: 15px;
	}

	.right-times {
		left: 0%;
	}

	.booking-next {
		width: 100% !important;
	}

	.footer-bar-button-box ul li img {
		width: 24px;
		display: inline-block;
	}

	.footer-bar-button-box ul li.custom-footer-icon img {

		width: 28px;
		display: inline-block;

	}

}

@media(min-width:768px) {
	.dropdown-menu-custom {
		margin-left: -272px !important;
		margin-top: 37px !important;
		min-width: 275px !important;
		min-height: 50px !important;
	}
}

@media(min-width:992px) {
	.dropdown-menu-custom {
		margin-left: -272px !important;
		margin-top: 37px !important;
		min-width: 275px !important;
		min-height: 50px !important;
	}
}

@media(min-width:1200px) {
	.dropdown-menu-custom {
		margin-left: -272px !important;
		margin-top: 37px !important;
		min-width: 275px !important;
		min-height: 50px !important;
	}

	.header-message {
		text-align: left !important;
		float: left;
		padding-left: 60px;
	}

	.siv_rightside_box {
		padding-top: 39px;
	}
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
	background-color: #5e5e5e;
	border: 1px solid #fff;
}

article.banner-tabss1 {
	position: inherit;
	transform: translateY(0);
	-webkit-transform: translateY(0);
}

@media(max-width: 991px) {
	ul.nav.navbar-nav.flag-sec-respons {
		border-left: 0;
		padding-left: 0;
	}
}

@media (max-width: 480px) and (min-width: 320px) {
	article.banner-tabss {
		padding: 30px 0 10px;
	}

	ul.nav.nav-tabs.ban-tab-ul li.nav-item {
		margin: 40px 5px 0 !important;
	}

	.header-message {
		text-align: center !important;
		display: block;
	}
}

.css-yk16xz-control {
	min-height: 50px !important;
}

.box {
	background-image: linear-gradient(to right, #ebebeb calc(50% - 100px), #c5c5c5 50%, #ebebeb calc(50% + 100px));
	background-size: 0;
	height: 200px;
	position: relative;
	overflow: hidden;
}

.box::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	width: calc(200% + 200px);
	bottom: 0;
	background-image: inherit;
	animation: move 2s linear infinite;
}

@keyframes move {
	to {
		transform: translateX(calc(50% + 100px));
	}
}

.loader1 {

	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;

}

.loading-text {
	text-align: center;
	position: absolute;
	top: 40%;
	width: 100%;
	margin: 0px auto;
}

.roroId .pot-form select {
	height: 48px !important;
}

.roroId .iciELI {
	width: 100% !important;
	max-width: 100% !important;
}

.innerLabelright {
	position: absolute;
	right: 25px;
	top: 50px;
	color: #666;
}

.unsubscribe-subs h4 {
	font-size: 18px;
	color: #123c60;
	margin-bottom: 12px;
	font-weight: bold;
	padding: 0px;
	text-transform: uppercase;
	text-align: center;
}

.slick-dots li button:before {
	font-family: 'slick';
	font-size: 6px;
	line-height: 20px;
	position: absolute;
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
	content: '•';
	text-align: center;
	opacity: .25;
	color: #fff !important;
	-webkit-font-smoothing: antialiased;
	background: #000;
	border-radius: 50%;
	-moz-osx-font-smoothing: grayscale;
}

.carousel .slide .banner-fin1 img {
	width: 100%;
	vertical-align: top;
	border: 0;
	max-height: 60vh;
	object-fit: cover;
	object-position: top;
}

/* tools-card */
.tools-card .card-item {

	border-bottom: dashed 1px #7c7c7c;
}

/* .tools-card .card-item .card-data {
	min-width: 120px;
} */

.tools-card .card-header {
	background: #fff;
	border-bottom: 3px solid #fff !important;

}

.card-element-head {

	color: #123c60;
	font-size: 18px;
	font-weight: 800;
}

.card-element-head span.card-info {
	margin-left: 20px;
	color: #7c7c7c;
	font-size: 16px;
	font-weight: 400 !important;
}


.tools-card .card-item img.card-icon {
	width: 30px;
	height: 30px;
	object-fit: contain;
}

.tools-card .card-item .card-desktop-view {
	display: flex;
	column-gap: 8px;
	justify-content: space-between;
	position: relative;
	align-items: center;
}

.tools-card .card-item .card-desktop-view .btn {
	padding: 0px;
}

.tools-card .vessel-details {
	width: 30%;

}

.tools-card .accordion {
	border: none !important;
}

.tools-card .accordion .card {
	border: none !important;
}

.tools-card .accordion .card .card-header {
	box-shadow: 0 2px 13px #cccccc79;
}

.tools-card .search-result {
	color: #123c60;
	font-size: 18px;
	font-weight: 800;
}

.tools-card .tools-title {
	align-items: center;
	padding: 10px 30px;
	display: flex;
	justify-content: center;
	gap: 40px;
	color: #123c60;

}

.tools-card .filter-base .select {

	width: 150px;

}

.tools-card.tools-title img.card-icon {
	height: 40px;
	width: 40px;
	font-weight: bold;
}

.tools-card .card-body {
	background: #e3e3e3;
}


.default-arrow::before {

	content: url(../src/assets/card-upsvg.svg);
	left: -10px;
	top: -6px;
	transform: rotate(90deg);
	transition: all .5s;
	position: absolute;
	cursor: pointer;

}

i.default-arrow.active:before {
	transform: rotateX(180deg) !important;
	transition: all .5s;
}


.accordion .collapse.show>.default-arrow::after {
	content: url(../src/assets/card-upsvg.svg);
	transform: rotate(90deg);
}

.card-data.card-first-element {
	position: relative;
	width: 21%;
}

.card-data.card-second-element {
	width: 17%;
}

.card-data.card-third-element {
	width: 20%;
}

.inner-card-detail .card-icon {
	width: 30px;
	height: 30px;
	object-fit: contain;
	margin-top: 25px;
}

.inner-card-detail li:first-child .line {
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
	border-top-width: 2px;
}

.inner-card-detail li:last-child .line {
	border-bottom: 2px solid #707070;
	border-bottom-left-radius: 7px;
	border-bottom-right-radius: 7px;
	bottom: -20px;
}

.inner-card-detail .line {
	background-color: #133d60;
	border: solid #707070;
	border-width: 0 2px;
	bottom: -21px;
	left: 0;
	position: absolute;
	top: -21px;
	width: 42px;
}

.inner-card-detail ul.card-detail-list {
	position: relative;
	list-style-type: none;
	padding: 0;
}


.inner-card-detail ul li:first-child .row-line:before {
	background: #123c60;
	border-radius: 2px;
}

.inner-card-detail ul li:last-child .row-line:before {
	background: #6ec145;
	border: none;
	border-radius: 2px;
}


.inner-card-detail .row-line:before {
	border: 1px dashed #123c60;
	content: "";
	height: 2px;
	left: 0;
	opacity: 1;
	position: absolute;
	right: 15px;
	left: 48px;
	top: 0;
}

.inner-card-detail li {
	padding: 0 0 0 48px;
	position: relative;
}

.inner-card-detail .card-detail-data {
	padding: 25px 25px;
}

.inner-card-detail ul li:last-child .card-detail-data {
	padding: 10px 10px;
}

.tools-card .card-body .line img {
	height: 25px;
	object-fit: contain;
	position: absolute;
	left: 6px;
	top: 16px;
}

.bottom-arrow.open {
	border-bottom: 5px solid #fdc200;
	position: relative;
	position: fixed;
	bottom: 0px;
	width: 100%;
}

.bottom-arrow {

	position: relative;
	position: fixed;
	bottom: 70px;
	width: 100%;
}

.arrow a {
	background-color: #fdc200;
	padding: 3px;
	height: 25px;
	width: 25px;
	float: right;
	margin-right: 50px;
	margin-top: -20px;
}

@media only screen and (max-width: 768px) {
	.bottom-fixed-bar-search {
		height: 75px;
		border-radius: 50px 50px 0px 0px;
	}

	.footer-bar-tracker-box {
		margin: 10px 0px;
	}

	.bottom-arrow {
		border-bottom: 0px solid #fdc200;
		position: relative;
		position: fixed;
		bottom: 73px;
		width: 100%;
		/* border-radius: 50px 50px 0px 0px; */
	}

	.tools-card .card-item .card-desktop-view {
		display: flex;
		column-gap: 8px;
		justify-content: space-around;
		position: relative;
		align-items: center;
	}

}

.point-to-point-select {
	width: 200px;
}

@media (min-width: 1400px) {
	.tools-card .container {
		max-width: 1320px;
	}

}

@media only screen and (max-width: 485px) {
	.tools-card .card-item .card-data {
		min-width: auto;
		margin-left: 0px;
	}

	.tools-card .card-item .card-desktop-view {
		align-items: center;
		column-gap: 0px !important;
	}

	.default-arrow::before {

		left: 12px !important;
		top: 18px !important;
		transform: rotate(90deg);
		transition: all .5s;
		position: absolute;
		cursor: pointer;
	}

	.tools-card .tools-title {
		align-items: flex-start;
		padding: 0px 20px;
		display: flex;
		justify-content: flex-start;
		gap: 5px;
		color: #123c60;
		flex-direction: column;
	}


	.tools-card .tools-title h3 {
		font-size: 22px !important;
	}

	.card-data-m {
		padding-left: 30px !important;
	}

	.ebl-card {
		background: #123c60;
		color: white;
		width: 100% !important;
	}

	.ebl-card-sec {
		grid-template-columns: 1fr !important;
	}
}

.custom-width {
	min-width: 182px !important;
}


.ebl-card-sec {
	display: grid;
	gap: 10px;
	grid-template-columns: repeat(3, 1fr);
}

.ebl-card {
	background: #123c60;
	color: white;
	width: calc(100% - 10px);
}


.ebl-video-sec video {
	background: black;
	height: 500px;
	margin-top: 80px;
	margin-bottom: 80px;
	border-radius: 50px;
}

.ebl-video-sec {
	position: relative;
}

.ebl-play-btn {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.ebl-play-btn img {
	background: #fff;
	padding: 10px;
	border-radius: 50%;
}

.ebl-tittle {
	font-size: 41px;
	font-weight: 600;
	line-height: inherit;
	margin: 0;
	color: #123c60;
}

.accodian-sec input.form-control.form-control-lg {
	border-radius: 50px 0px 0 50px;
}

.accodian-sec span.search-btn {
	background: #123c60;
	padding: 12px 40px;
	border-radius: 0px 50px 50px 0px;
}

.accodian-sec span.search-btn a {
	color: white;
}




/* Schedule-card-design-css */

.schedule-results-container {
	margin-top: 80px;
}

.schedule-header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 74px;
}

.schedule-header .schedule-title {
	color: #002d72;
	display: flex;
	flex-wrap: wrap;
	font-family:FSIndustrieNw-Bold,sans-serif!important;
	font-size: 32px;
	line-height: 40px;
}

.schedule-header .schedule-trade-name {
	font-weight: 600;
}

.schedule-header .schedule-line-name {
	font-weight: 300;
	margin-left: 5px;
}



.schedule-header .schedule-icons-block {
	display: flex;
	gap: 25px;
}

.schedule-header .schedule-icons-block .image-print {
	cursor: pointer;
	height: 30px;
	width: 35px;
}

.schedule-results-container .schedule-routes-data-card {
	grid-gap: 70px;
	border: 1px solid #d8e3f2;
	border-radius: 10px;
	display: grid;
	font-family: FSIndustrieNw, sans-serif;
	font-size: 18px;
	gap: 70px;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	height: auto;
	line-height: 24px;
	margin-bottom: 30px;
	min-height: 100px;
	padding: 10px 40px 20px;

}

.schedule-results-container .schedule-routes-data-card .schedule-title-route-card {
	font-weight: 700;
	font-size: 24px;
	margin-bottom: 10px;
	color: #002d72;
}

.schedule-results-container .schedule-routes-data-card .schedule-details-route-card {
	font-weight: 400;
}

.nav-tabs.schedule-tabs {
	border-bottom: 1px solid #d8e3f2;
	display: flex;
	margin-top: 70px;
}

.schedule-tabs .nav-link.active {
	border: initial;
	border-bottom: 4px solid #009cde !important;

}

.schedule-tabs .nav-item {
	background: #fff;
	border: initial;
	border-bottom: 4px solid #0000;
	color: #18191b;
	font-family: FSIndustrieNw-Regular, sans-serif;
	font-size: 1rem;
	font-weight: 700;
	height: 40px;
	justify-content: center;
	line-height: 26px;
	padding: 0 20px;
	text-align: center;
	/* width: 257px; */
	margin-bottom: 0px !important;
}

.nav-tabs.schedule-tabs .nav-link:focus,
.nav-tabs.schedule-tabs .nav-link:hover {

	border-color: #ffffff0f #ffffff0f #ffffff0f;

}

.tab-content .main-grid-div {
	display: flex;
	flex-direction: column;
	margin-top: 82px;
	width: 100%;
}

.tab-content .main-grid-div .schedule-line-card {
	grid-row-gap: 20px;
	align-items: flex-start;
	border: 1px solid #d8e3f2;
	border-radius: 10px;
	box-shadow: 0 4px 20px 0 #00000026;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
	height: auto;
	margin-bottom: 30px;
	min-height: 100px;
	padding: 20px 40px;
	row-gap: 20px;
}

.tab-content .main-grid-div .schedule-line-card .schedule-grid-one-block:last-child {
	grid-column: span 1;
}


.tab-content .main-grid-div .schedule-line-card .schedule-grid-one-block .item-style.bold {
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
}

.tab-content .main-grid-div .schedule-line-card .schedule-grid-one-block .translation-title {
	font-family: FSIndustrieNw-Regular, sans-serif;
	font-size: 1rem;
	font-weight: 700;
	line-height: 26px;
}


.custom-schedule-button {
	align-items: center;
	background-color: #0000;
	border: none;
	color: #002d72;
	cursor: pointer;
	display: flex;
	font-family: FSIndustrieNw-Regular, sans-serif;
	font-size: 18px;
	font-weight: 400;
	gap: 12px;
	max-width: 100%;
	outline: none;
	padding: 8px 12px;
	text-decoration: underline;
}

.schedule-custom-select .select-schedule-arrow {
	align-items: center;
	display: flex;
}

.schedule-custom-select .select-schedule-arrow .arrow-icon {
	cursor: pointer;
	transition: transform .3s ease;
}

.schedule-custom-select .select-schedule-arrow .arrow-icon.rotate {
	transform: rotate(180deg);
}

.schedule-custom-select {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 10px;
	position: relative;
}

.schedule-custom-select .schedule-options-list.visible {
	border-radius: 10px;
	list-style: none;
	min-width: 200px;
	opacity: 1;
	padding: 10px 5px;
	transform: translateY(0);
	visibility: visible;
	width: auto;
}

.schedule-custom-select .schedule-options-list {
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0 4px 20px 0 #00000026;
	opacity: 0;
	overflow-y: auto;
	position: absolute;
	right: 0;
	top: 45px;
	transform: translateY(-10px);
	transition: opacity .1s ease, transform .3s ease, visibility .3s ease;
	visibility: hidden;
	z-index: 1000;
}

.schedule-custom-select .schedule-options-list li {
	border-bottom: 1px solid #d8e3f2;
	color: #002d72;
	cursor: pointer;
	font-family: FSIndustrieNw-Regular, sans-serif;
	font-size: 18px;
	margin: 0 20px;
	padding: 8px 0;
}

.schedule-custom-select .schedule-options-list li:last-child {
	border-bottom: initial;
}

.schedule-card .card-header {
	position: relative;
	width: 100%;
}

.schedule-card .schedule-card-info {
	grid-gap: 20px;
	display: grid;
	gap: 20px;
	grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
	padding: 20px 40px;
}

.custom-accordion-button {
	align-items: center;
	background-color: #0000;
	border: none;
	color: #002d72;
	cursor: pointer;
	display: flex;
	font-family: FSIndustrieNw-Regular, sans-serif;
	font-size: 18px;
	font-weight: 400;
	gap: 12px;
	max-width: 100%;
	outline: none;
	padding: 8px 12px;
	text-decoration: underline;
}

.custom-accordion-button .toggle-arrow {
	cursor: pointer;
	transition: transform .3s ease;
	width: 25px;
}

.custom-accordion-button .toggle-arrow.rotate {
	transform: rotate(180deg);
}

.schedule-card-container .accordion .schedule-card.card {
	border: 1px solid #d8e3f2;
	border-radius: 10px;
	box-shadow: 0 4px 20px 0 #00000026;
	display: flex;
	height: auto;
	margin-bottom: 30px;
	min-height: 100px;
}

.schedule-card-container .accordion {
	border: none;
	border-radius: 2px;
}

.schedule-card-container .accordion button:focus {
	outline: none;
}

.schedule-card-container .accordion .tooltip-block {
	margin-left: 10px;
	position: relative;
}

.schedule-card-container .accordion .tooltip-block .tool-tip {
	background: #fff;
	border: 1px solid #d8e3f2;
	border-radius: 10px;
	box-shadow: 0 4px 20px 0 #00000026;
	color: #002d72;
	font-family: FSIndustrieNw-Regular, sans-serif;
	font-size: 18px;
	left: 33px;
	padding: 0 15px;
	position: absolute;
	top: -120px;
	transition: opacity .3s ease;
	width: 400px;
	z-index: 10;
}

.schedule-card-container .accordion .tooltip-block .tool-tip .tooltip-grid {
	grid-row-gap: 20px;
	grid-column-gap: 50px;
	column-gap: 50px;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
	margin: 18px 0;
	row-gap: 20px;
}

.schedule-card-container .accordion .tooltip-block .tool-tip .close-tool-tip {
	cursor: pointer;
	position: absolute;
	right: 20px;
	top: 18px;
}

.schedule-card-container .accordion .tooltip-block .tool-tip:after {
	border: 8px solid #0000;
	border-right-color: #fff;
	content: "";
	margin-top: 2px;
	position: absolute;
	right: 100%;
	top: 50%;
}

.schedule-card-container .accordion .card-header {
	background: none;
	padding: 0px;
}

.schedule-card-container .accordion .card-body {
	background-color: #ebf1f8;
	padding: 0;
}

.schedule-card-container .accordion button.custom-accordion-button {
	cursor: pointer;
	position: absolute;
	right: 20px;
	top: 20px;
	transition: transform .4s ease;
	height: 10px;
}

.schedule-card-container .accordion .grid-item .translation-style {
	font-family: FSIndustrieNw-Regular, sans-serif;
	font-size: 1rem;
	line-height: 1.5;
	font-weight: 600;
	margin-bottom: 5px;
}

.schedule-card-container .accordion .grid-item .tooltip-block .tool-tip .title {
	font-weight: 600;
}

.schedule-card-container .accordion .grid-item .tooltip-block .tool-tip .data {
	font-weight: 400;
}

.schedule-card-container .accordion .grid-item .vsl-voyage, .schedule-card-container .accordion .grid-item .schedule-link  {
	font-family: FSIndustrieNw-Regular, sans-serif;
	font-size: 1rem;
	font-weight: 600;
	line-height: 35px;
}
.schedule-card-container .accordion .grid-item .next-port-text{
	font-family: FSIndustrieNw-Regular, sans-serif;
	font-size: 1rem;
	font-weight: 600;
	line-height: 35px;
}

.schedule-card-container .accordion .grid-item .vessel-name-tooltip a {
	color: #002d72;
	font-family: FSIndustrieNw-Regular, sans-serif;
	font-size: 1rem;
	line-height: 24px;
}

.schedule-card-container .accordion .result-inner-card .card-inner-top {
	grid-gap: 20px;
	border-top: 1px solid #d8e3f2;
	display: grid;
	gap: 20px;
	grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
	padding: 20px 40px;
}

.schedule-card-container .accordion .result-inner-card .cut-off-dates {
	border-top: 1px solid #d8e3f2;
	margin: 0 40px;
	padding: 20px 0;
}

.schedule-card-container .accordion .result-inner-card .cut-off-dates .dates-container {
	align-items: flex-start;
	display: flex;
}

.schedule-card-container .result-inner-card .cut-off-dates .dates-container .dates-grid {
	grid-row-gap: 25px;
	grid-column-gap: 10px;
	column-gap: 10px;
	display: grid;
	flex: 1 1;
	grid-template-columns: repeat(3, 1fr);
	overflow: hidden;
	row-gap: 25px;
	transition: max-height .6s ease;
}

.schedule-card-container .result-inner-card .cut-off-dates .dates-container .button-wrapper {
	align-items: center;
	display: flex;
	flex: 0 0 11%;
	justify-content: flex-end;
}

.schedule-card-container .result-inner-card .cut-off-dates .dates-container .button-wrapper button {
	background: #0000;
	border: none;
	color: #002d72;
	font-family: FSIndustrieNw-Regular, sans-serif;
	font-size: 18px;
	line-height: 24px;
	padding: 0 3px;
	text-decoration: underline;
}

.schedule-card-container .result-inner-card .cut-off-dates .dates-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	transition: transform .4s ease;
}

.schedule-card-container .result-inner-card .cut-off-dates .dates-grid.collapsed .date-item:nth-child(n+4) {
	display: none;
	transition: transform .4s ease;
}

.card-inner-top .text-bold {
	font-family: FSIndustrieNw-Bold, sans-serif;
	font-size: 1rem;
	font-weight: 600;
	line-height: 24px;
}



/* tariff_calculator */

.common-padd {
	padding-top: 80px;
	padding-bottom: 80px;
}

.custom-card-container {
	position: relative;
}

.custom-card-container .accordion {
	border: none;
	border-radius: 4px;
}


.custom-card-container .card {
	background-color: #fff;
	border: 1px solid #d8e3f2;
	border-radius: 10px;
	box-shadow: 0 4px 20px 0 #00000026;
	display: flex;
	height: auto;
	margin-bottom: 30px;
	min-height: 100px;
}

.tariff-card.custom-card-container .card-grid-container {
	grid-gap: 20px;
	display: grid;
	gap: 20px;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	padding: 10px 10px;
}


.custom-card-container .accordion button.custom-toggle-link {
	position: absolute;
	right: 10px;
	top: 22px;
	border: none;
	background: none;

}

.custom-card-container .card-grid-container .grid-item .card-tittle {
	font-family: FSIndustrieNw-Regular, sans-serif;
	font-size: 20px;
	line-height: 26px;
	margin-bottom: 15px;
}

.custom-card-container .card-grid-container .grid-item .card-data {
	font-family: FSIndustrieNw-Bold, sans-serif;
	font-size: 18px;
	font-weight: 600;
	line-height: 24px;
}

.custom-card-container .accordion .card-body .data-table-tariff .card-tittle {
	font-family: FSIndustrieNw-Regular, sans-serif;
	font-size: 20px;
	line-height: 26px;
	margin-bottom: 15px;
}

.custom-card-container .accordion .card-body {
	background-color: #ebf1f8;
}


.custom-card-container .card-header {
	position: relative;
	background-color: #fff !important;
	border-radius: 10px !important;
}

.custom-card-container .custom-toggle-link .arrow-icon {
	transition: transform 0.3s ease;
}

.custom-card-container .custom-toggle-link .arrow-icon.rotate {
	transform: rotate(90deg);
}

.custom-card-container button:focus {
	outline: none;
}
.custom-card-container .arrowButton {
	top: 0px;
    right: 10px;
}


.remark-block.no-remark {
	position: relative;
	/* background-image: url(./assets/cmd-icon.webp); */
	background-image: url(./assets/comment-desktop-icon.webp);
	width: 30px;  /* Adjust size as needed */
	height: 30px;
	background-size: contain;
	transition: background-image 0.3s ease;
}

.remark-block.no-remark:hover {
	position: relative;
	background-image: url(./assets/remarks-hover-icon.svg);
}

.remark-tooltip {
	display: none; /* Hidden by default */
	background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 20px 0 #00000026;
    position: absolute;
	left: 30px;
    transition: opacity .1s ease, transform .3s ease, visibility .3s ease;
    z-index: 1000;

  }

  .remark-block.no-remark:hover .remark-tooltip {
	display: block !important; /* Show on hover */
	
  }



@media only screen and (max-width: 768px) {
	.data-table-tariff .grid-container {
		display: grid;
		 grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr !important;
	}

	.data-table-tariff .grid-container .grid-item:nth-child(1) {
		grid-column: span 2; /* First row spans all columns */
	  }
	  
	  .data-table-tariff .grid-container .grid-item:nth-child(2),
	  .data-table-tariff .grid-container .grid-item:nth-child(3),
	  .data-table-tariff .grid-container .grid-item:nth-child(4) {
  grid-column: span 1; /* Second row: three items, one per column */
}
.data-table-tariff .grid-container .grid-item:nth-child(5),
.data-table-tariff .grid-container .grid-item:nth-child(6) {
  grid-column: span 2; /* Third row: two items, one per column */
}
.schedule-card-container .accordion .result-inner-card .card-inner-top{
	grid-template-columns: 1fr 1fr 1fr ;
	padding: 20px 40px;
}
	
}






@media only screen and (max-width: 485px) {

	.schedule-card .schedule-card-info {
		grid-template-columns: repeat(auto-fit, minmax(100px, 2fr));
	}

	.container .button-wrapper {
		align-items: center;
		display: flex;
		flex: 0 0 27% !important;
		justify-content: flex-end;
	}

	.schedule-card-container .result-inner-card .cut-off-dates .dates-container .dates-grid {
		grid-template-columns: repeat(1, 1fr);
	}

	.mobile-border {
		border-bottom: 1px solid #e3e3e3;
	}

	.data-table-tariff .grid-container {
		overflow: scroll;

	}

	.custom-card-container .card-grid-container {
		padding: 15px 0px;
	}

	.schedule-tabs .nav-item {
		font-size: 17px;
		width: 172px;
		height: 100px;
	}

	.schedule-card-container .accordion button.custom-accordion-button {

		right: 5px;
		top: 18px;
	}

	.custom-accordion-button .toggle-arrow {
		width: 25px;
	}

	.tab-content .main-grid-div .schedule-line-card {

		grid-template-columns: repeat(auto-fit, minmax(130px, 2fr));

		padding: 20px 20px;

	}

	.tab-content .main-grid-div .schedule-line-card .schedule-grid-one-block .item-style.bold {
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
	}

	.tariff-card.custom-card-container .accordion button.custom-toggle-link {
		right: 10px;
		top: 22px;

	}

	.custom-card-container .custom-toggle-link .arrow-icon {
		transition: transform 0.3s ease;
		
		object-fit: contain;
	}
	.data-table-tariff .grid-container {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
	}
	.data-table-tariff .grid-container .grid-item:nth-child(1) {
		grid-column: 1 / -1; /* First row spans all columns */
	  }
	  .data-table-tariff .grid-container .grid-item:nth-child(2),
	  .data-table-tariff .grid-container .grid-item:nth-child(3),
	  .data-table-tariff .grid-container .grid-item:nth-child(4) {
  grid-column: span 2; /* Second row: three items, one per column */
}
.data-table-tariff .grid-container .grid-item:nth-child(5),
.data-table-tariff .grid-container .grid-item:nth-child(6) {
  grid-column: span 3; /* Third row: two items, one per column */
}

.data-table-tariff .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 8px;
    padding: 8px;
   
}

	.trackShipmentResultRow .checkbox {
		margin-top: 5px;
		position: absolute;
		left: 15px;
	}

	.custom-card-container .accordion .card-body{
		padding: 10px 10px;
	}


	

	.tariff-card.custom-card-container .card-grid-container .grid-item:nth-child(1),
	.tariff-card.custom-card-container .card-grid-container .grid-item:nth-child(2)
	{
			grid-column: span 3; /* First row spans all columns */
		  }
		 
		  .tariff-card.custom-card-container .card-grid-container .grid-item:nth-child(3),
		  .tariff-card.custom-card-container .card-grid-container .grid-item:nth-child(4)
		  {
	  grid-column: span 3; /* Second row: three items, one per column */
	}
	.tariff-card.custom-card-container .card-grid-container .grid-item:nth-child(5),
	.tariff-card.custom-card-container .card-grid-container .grid-item:nth-child(6) {
	  grid-column: span 3; /* Third row: two items, one per column */
	}
	
	.schedule-card-container .accordion .result-inner-card .card-inner-top{
		grid-template-columns: 1fr 1fr 1fr 1fr;
		padding: 20px 40px;
	}

	.schedule-card-container .accordion .result-inner-card .card-inner-top .grid-item:nth-child(1){
		grid-column: span 3;
	}
	.schedule-card-container .accordion .result-inner-card .card-inner-top .grid-item:nth-child(2),
	.schedule-card-container .accordion .result-inner-card .card-inner-top .grid-item:nth-child(3){
		grid-column: span 2;
	}

	.schedule-header {
		flex-direction: column;
	}

	.schedule-header .schedule-line-name {
		margin-left: 0px;
	}
	


}





.data-table-tariff .grid-container {
	display: grid;
	grid-template-columns: 2fr 1fr 1fr 1fr 1fr 2fr;
	gap: 10px;
	padding: 15px;
	background: white;
    border-radius: 8px;
    justify-content: space-between;

}

.data-table-tariff .grid-header {
	font-weight: bold;
	/* text-align: center; */
	padding-bottom: 6px;
	font-size: 18px;
	font-family: FSIndustrieNw-Bold, sans-serif;
}

.data-table-tariff .grid-item {
	/* text-align: center; */
	padding: 6px;
	font-size: 14px;
	font-family: FSIndustrieNw-Regular, sans-serif;
}

.data-table-tariff .final-row {
	font-weight: bold;
	font-family: FSIndustrieNw-Bold, sans-serif;
	color: #001524;
}

/* .data-table-tariff .grid-item.final-row {
	text-align: right;
  } */

/* Make the first and last items span more space */
.data-table-tariff .grid-container .grid-item.first-child {

	grid-column: span 2;
	/* First item will take up 2 columns */
}

.data-table-tariff .grid-container .grid-item.last-child {
	grid-column: span 2;
	/* Last item will take up 2 columns */
}