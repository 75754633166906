.press-release-base h3 a{
    font-size:24px;
    color:#123c60;
    font-weight: 700;
}
.press-release-base h6{
     font-size:12px;
     color:#656060;
}
.press-release-content{
    padding-top:20px;
}
.press-release-content p{
    font-size:15px;
    line-height:24px;
}
.press-release-readmore{
        padding: 10px 10px 0;
    margin-top:10px;
    border-top:1px solid #ccc;
    display:inline-block;
    width:100%;
    
    
}
.press-release-readmore a{
    float:right;
    color:#123c60;
    font-weight: 600;
    text-decoration: none;
}
.press-release-readmore a span{
    display:inline-block;
    margin-left:10px;
}
.press-release-inner {
        padding-left: 30px;
    margin: 35px 0;
    border-left: 3px solid #fdc200;
    width: 100%;
    display: inline-block;
}