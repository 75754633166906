@font-face {
	font-family: 'FSIndustrieNw-Bold';
	src: url('./../../../font/fs/FSIndustrieNw-Bold.woff');
	}
    @font-face {
        font-family: 'FSIndustrieNw-Regular';
        src: url('./../../../font/fs/FSIndustrieNw-Regular.woff');
        }
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{font-family: 'FSIndustrieNw-Bold', sans-serif !important;}
p{font-family: 'FSIndustrieNw-Regular';}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.tools-content img {
    width: auto !important;
    height: 40px; 
}
.container-new{
    width:93%;
    margin:0 auto;
   
} 
body{
    padding:0;
    margin:0;
}
.tools-carosuel-btn a{
   
    background: #123c60;
    border: none;
    margin: 5px;    
    outline: none;
    padding: 4px 10px;
    z-index: 9999;
    cursor: pointer;
}

ul.tool-flex{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 0px;
    list-style-type: none;
}
.tools-content ul li {
    max-width: 260px;
    text-align: center;
    padding: 0px 10px;
}

.tools-service .service-sec-21{
    display: block !important;
    padding:0px;
    padding-bottom: 30px;
}
.carousel-status{
    display: none;
}
.btn.btn-default{
    background-color: #FDC200;
    border: 5px solid #FDC200;
    color: #001524;
    font-size: 18px;
    font-weight: 600;
    font-family: 'FSIndustrieNw-Bold';
    padding: 0px 22px !important;
    
}
.btn.btn-default-home {
    background-color: #FDC200;
    border: 5px solid #FDC200;
    color: #001524;
    font-size: 16px;
    font-weight: 400;
    font-family: 'FSIndustrieNw-Bold';
    padding: 0px 22px;
    
}
h2 {
    color: #123c60!important;
    font-style: normal;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    font-size: 50px!important;
}
.container-new.service-box {
    padding-top: 80px;
}
.container-fluid.tools-background {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    top: -5px;
    
}

.company-news h3{
    color: #123C60;
    padding-left: 10px;
}
.news-cont-sec-img img{
    width: auto !important;
}
.service-sec-1 .tools-heading {
    text-align: end;
}
.service-sec-1 .tools-heading h2 {
    color: #123c60;
    font-style: normal;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    font-size: 34px;
}
.service-sec-1 .tools-heading p{
    color: #666666;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}
.service-sec-1 .tools-img img {
    width: 100%;
    position: absolute;
    bottom:-5px;
    right: 0%;
}
.banner-design {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 54%;
    background-repeat: no-repeat;
    background-size: contain;
}
.tools-heading p {
    color: #123C60;
    font-size: 20px;
    max-width: 400px;
    margin-bottom: 50px;
}

.tools-content .content-text p {
    font-size: 16px;
    color: #123C60;
    line-height: 20px;
    
}
.min-height{
    min-height: 160px;
}
.tools-content .content-text h6 {
    font-size: 20px;
    color: #123c60;
    font-weight: 600;
    line-height: 24px;
    padding-top: 30px;
    padding-bottom: 5px;
}

.service-box .service-sec-2 {
    display: flex;
    flex-wrap: wrap;
}
.service-sec-2 .tools-content{
    text-align: center;
    padding: 10px;
}

.vgm-content-box .solas-vgm-heading { 
    text-align: center;
    margin-bottom: 40px;
}
.vgm-content-box p {
    min-height: 50px;
    font-size: 16px;
    color: #ffffffc9;
}
.solas-vgm .vgm-box-bg {
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 80px;
    padding-top: 80px;
    background: #133D60;
  
}
.vgm-content-box .submit-row-box,.social-reg-box{
    padding: 0;
}
.submit-row-box .submit-vgm,.social-reg,.mass-upload {
    padding: 0;
}
.submit-vgm-img,
.social-reg-img,
.mass-upload-img {
    width: 100%;
    height: 100%;
}
.submit-vgm-img img,
.social-reg-img img,
.mass-upload-img img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.submit-vgm-content-text,
.social-reg-content-text,
.mass-upload-content-text {
    align-self: center;
    padding: 10px 0px;
    color: #fff;
    font-size: 12px;
}
.vgm-massupload-box  {
    padding: 0;
}
.container.vgm-content-box {
    padding-bottom: 40px;
}
.submit-vgm-content-text h4,
.social-reg-content-text h4,
.mass-upload-content-text h4{
    font-weight: bold;
    font-size: 22px;
    line-height: 22px;
}
.vgm-form-button button.content-text-btn {
    background-color: #fecc05;
    border: none;
    border-radius: 50px;
    padding: 7px 23px;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins';
    cursor: pointer;
}
.vgm-form-button button.content-text-btn:hover{
    background-color: #123c60!important;
    color: #fff;
    border: 1px solid #123c60!important;
}
.mass-form-button {
    display: flex;
}
.mass-form-button .mass-up-input {
    background: #123c60;
    border: none;
    color: #fff;
    padding: 6px;
    width: 350px;
    font-size: 12px;
}
button.mass-content-text-btn {
    background: #fecc05;
    border: none;
    border-radius: 0px!important;
    padding: 7px 14px;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins';
    cursor: pointer;
}
.solas-vgm-heading h2 {
    color: #fff!important;
    font-size: 47px !important;
    font-weight: bold;
    text-align: start;
} 
.service-cont-1 .service-cont-img .service-cont-main-img {
    width: 100%;
    object-fit: cover;
} 
.service-cont-3 .service-cont-img .service-cont-main-img {
    width: 100%;
    height: 400px;
    object-fit: cover;
} 
.service-cont-2 .service-cont-img .service-cont-main-img {

    height: 250px;
    object-fit: cover;
} 
.service-cont-1 .service-cont-img .service-img-ic img {
    width: 100%;
} 
.service-cont-1 .service-cont-text {
    text-align: left;
    padding-top: 20px;
}
.service-cont-1 .service-cont-img {
    position: relative;
}
.service-cont-1 .service-img-ic {
    width: 60px;
    height: 60px;
    padding: 14px;
    border-radius: 56%;
    background-color: #fff;
    margin: 0;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    right: 0;
    bottom: -26px;
    box-shadow: inset 0px 7px 0px -2px #FDC200;
}
.service-cont-1 .service-cont-text h6 {
    font-size: 22px;
    font-family: 'Poppins';
    font-weight: bold;
    color: #123c60;
}
.service-cont-1 .service-cont-text p {
    font-size: 16px;
    color: #001524;
    min-height: 72px;
}
.service-cont-2 .service-cont-img .service-img-ic img {
    width: 100%;
} 
.service-cont-2 .service-cont-text {
    text-align: center;
    padding-top: 35px;
}
.service-cont-2 .service-cont-img {
    position: relative;
}
.service-cont-2 .service-img-ic {
    width: 60px;
    height: 60px;
    padding: 14px;
    border-radius: 56%;
    background-color: #fff;
    margin: 0;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    right: 0;
    bottom: -26px;
    box-shadow: inset 0px 7px 0px -2px #FDC200;
}
.service-cont-2 .service-cont-text h6 {
    font-size: 22px;
    font-family: 'Poppins';
    font-weight: bold;
    color: #123c60;
}
.service-cont-2 .service-cont-text p {
    font-size: 14px;
    font-family: 'Poppins';
    color: #666666;
}
.container-new.sevices-box {
    margin-top: 60px;
}
.services-box-heading h2 {
    color: #123c60;
    font-size: 34px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 5px;
    font-family: 'FSIndustrieNw', sans-serif;
}
.service-cont-text button.service-cont-text-btn {
    background-color: #FDC200;
    border: 5px solid #FDC200;
    color: #001524;
    font-size: 16px;
    font-weight: 400;
    font-family: 'FSIndustrieNw-Bold';
    padding: 0px 22px;
}
.hovereffect:hover {
    transform: scale(1.05);
    transition: all .3s cubic-bezier(0,.89,.44,1);
}
.container-new.news-cont-box {
    margin-top: 80px;
    padding-bottom: 55px;
}
.news-cont-box .news-cont-heading{
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    align-items: center;
}
.news-carosouel-cont .carosouel-cont-bg{
    background-color: #fff;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px 6px;
    min-height: 121px;
}
.news-cont-sec-text p {
    font-weight: 350;
    font-family: 'FSIndustrieNw-Regular',sans-serif;
    margin: 0;
    color: #001524;
    text-align: left;
    font-size: 16px;
    padding-top: 5px;
}
.news-cont-box .news-carosouel-cont{
    padding: 10px;
}
.news-cont-sec-img {
    text-align: center;
    width: 100px;
    height: 100px;
    background-size: cover;
}
.news-cont-sec-img img {

        position: relative;
        top: 26px;
        left: 28px;
    
}
.news-carosouel-btn button.service-cont-text-btn {
    width: 30px;
    height: 30px;
    background: #123c60;
    border: none;
    margin: 5px;
    cursor: pointer;
    outline: none;
}
.carosouel-cont-bg .news-cont-sec{
    padding: 0;
}
a.news-cont-next-link {
    color: #123C60;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    float: right;
}
.news-cont-sec-text.col-xl-8.col-lg-8.col-md-8.col-sm-8.col-8 {
    padding: 0px 10px;
}
.industry-news .industry-box {
    padding: 80px 0;
}
.news-cont-heading h2 {
    color: #123C60 !important;
    font-size: 50px !important;
    font-weight: bold;
    padding-bottom: 10px;
}
.news_base .news_sub_title h4 {
    color: #123C60;
    padding-bottom: 10px;
    font-weight: 700;
    font-size: 20px;
    padding-top: 20px;
}
.news_bg_img {
 
padding: 2px 10px;
background: #FDC200;
border-radius: 6px;
}
img.news_icon {
    height: 10px;
    object-fit: contain;
}
.last-item {
    margin-top: 80px;
  }
.news_background {
    background-color: #fff;
    align-items: center;
    border-radius: 10px;
    padding: 10px 6px;
}
.news_flex{
    display: flex;
    list-style: none;
    justify-content: space-between;
}
.news_flex li a {
    color: #123C60 !important;
    text-decoration: none !important;
    font-size: 14px;
    font-weight: 500;
    float: right;
}
.news_flex li p{
    min-height: 50px;
    font-size: 13px;
    min-width: 220px;

}
.news_base ul li .news_innerpad {
    padding: 5px 10px;
}

.industy-heading h2,
.regulations-heading h2,
.whats-special-heading h2,
.get-intouch-heading h2  {
    color: #123c60;
    font-size: 50px !important;
    font-weight: bold;
    margin-bottom: 5px;
}
.ind-sec-img img,
.regulation-sec-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.indus-main-container,
.regulations-main-container {
    display: flex;
    background-color: #fff;
    box-shadow: 0px 5px 6px rgb(0, 0, 0, 10%);
    padding-top: 10px;
    margin-bottom: 16px;
    height: 150px;
}
.ind-sec-img,
.regulation-sec-img{
    padding: 0;
}

.ind-sec-content p,
.regulation-sec-cont p {
    padding-top: 10px;
    font-size: 14px;
    line-height: 22px;
    color: #000;
    font-weight: 400;
    font-family: 'FSIndustrieNw',sans-serif;
}
.whats-first-box{
    margin-left: 102px;
    padding: 50px 0;
}
.whats-first-box-container .whats-content-box {
    display: flex;
    flex-wrap: wrap;
    margin: 50px 0;
    padding-left: 0;
}
.container-fluid.whats-box {
    background: #f2f2f2;
}
.specs-cont-bg {
    background: #fff;
    display: flex;
    align-items: center;
    width: 249pxpx;
    padding: 30px 10px;
    margin-bottom: 10px;
    border-radius: 15px;
}
.specs-cont-title h6 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-family: 'Poppins';
}
.specs-cont-bg .specs-cont-icon img {
    width: 60px;
}
.container.contact-box {
    padding: 80px 0;
}

.get-intouch-heading,
.services-box-heading {
    text-align: center;
    margin-bottom: 60px!important;
}
.industy-heading,
.regulations-heading,
.whats-special-heading  {
    margin-bottom: 30px!important;
}
.contact-section-1 .contact-address {
    display: flex;
    padding-bottom: 25px;
}
.contact-address .contact-ic-bg {
    padding-right: 35px;
}

.contact-content p {
    font-size: 16px;
    font-weight: 400;
    font-family: 'FSIndustrieNw-Regular';
    color: #123C60;
}
.contact-content h6 {
    color: #123c60;
    font-size: 18px;
    font-weight: 800;
}
.contact-section-2 .country-codes{
    display: flex;
    align-items: center;
}
.form-row .form-list {
    padding-bottom: 15px;
}
.country-codes .code-input {
    width: 80px;
    margin-right: 10px;
    text-align: center;
}
.form-list .form-bg {
    background-color: #eeeeee;
    border: none;
    font-size: 16px;
    padding: 16px 12px;
    font-family: 'FSIndustrieNw-Regular';
}
.form-row .captcha-form-list{
    text-align:center;
}
.contact-section-2 button.form-submit-btn {
    background-color: #FDC200;
    width: 100%;
    border: none;
    padding: 10px 0 !important;
    font-size: 18px;
    font-weight: bold;
    margin: 0 5px;
}
hr.head-uline-1 {
    border: 1.5px solid #143e68;
    background-color: #143e68;
    width: 65px;
    margin-top: 0px;
    margin-bottom: 0;
}
hr.head-uline-2 {
    border: 1.5px solid #FDC200;
    background-color: #FDC200;
    width: 50px;
    margin-top: 0px;
    margin-bottom: 0;
}
.uline-box-right hr.head-uline-1,
.uline-box-right hr.head-uline-2{
    margin-right: 0;
}
.uline-box-right {
    margin-bottom: 10px;
}
.uline-box-white hr.head-uline-1 {
    border: 1.5px solid #fff;
    background-color: #fff;
}
.uline-box-left-bw hr.head-uline-1 {
    border: 1.5px solid #000;
    background-color: #000;
    width: 70px;
}
.uline-box-left-bw hr.head-uline-2 {
    border: 1.5px solid #fff;
    background-color: #fff;
}
.uline-box-left-bw hr.head-uline-1,
.uline-box-left-bw hr.head-uline-2{
    margin-left: 0;
}
.uline-box-left hr.head-uline-1,
.uline-box-left hr.head-uline-2{
    margin-left: 0;
}
.whats-first-box-container p {
    padding: 0 15px;
}
.whats-box .whats-special-video img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
textarea.form-control.form-bg.text-form-h {
    height: 150px;
}
#homeBannerIndex .carousel-slider button.control-arrow {
    
}
#homeBannerIndex .left-btn {
    display: inline-block;
    position: absolute;
    top: 52%;
    bottom: 0;
    z-index: 9;
    cursor: pointer;
}
#homeBannerIndex .right-btn {
    display: inline-block;
    position: absolute;
    top: 52%;
    bottom: 0;
    z-index: 9;
    right: 0;
    cursor: pointer;
}
#homeBannerIndex .left-btn img, #homeBannerIndex .right-btn img {
    background: #00000036;
    padding: 15px;
}
.car-radius-1,
.car-radius-3 {

    width: 100%;
    height: 150px;
    
}
.car-radius-2,
.car-radius-4 {
    width: 100%;
    height: 150px;
    
}
.car-radius-container {
    position: relative;
}
.car-radius-box-2 {
    width: 100%;
    top: 0;
}
.rad-bg-blue {

    background-color: #123c60!important;
    background-position: center;
    background-repeat: no-repeat;
}
.rad-bg-yellow {    
    background: #FDC200;
}


section.car-carrier {
    padding-top: 80px;
}
.car-carrier .car-carrier-section-1{
    padding-left: 0px;
    padding-right: 8px;
}
 .car-carrier-section-2{
    padding-left: 8px;
    padding-right: 0px;

}
.car-carrier-header-1, .car-carrier-header-2 {
    padding: 0px;
}


.text-end {
    text-align: end;
    display: flex;
    -webkit-justify-content: flex-end;
    
}

.videoButton {
    background: #FDC200;
    border-radius: 7.45763px;
    padding: 12px;
    text-align: center !important;
    width: 400px;
    margin-bottom: 38px;
    margin-top: 17px;
    border: none;
    color: #001524;
    font-weight: 600;
    font-size: 21px;
    border: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.videoButton img {
    padding-left: 30px;
}
/* .car-radius-box-2 .rad-border {
    text-align: center;
    padding-right: 100px;
    padding-top: 70px;
    padding-left: 30px;
    height: 250px;

} */
.car-radius-1,
.car-radius-2,.car-radius-3, .car-radius-4 {
    text-align: center;
    padding-right: 100px;
    padding-top: 140px;
    padding-left: 30px;
    height: 320px;
}
.car-radius-2 {
    margin-top: 16px;
}

.car-radius-3,
.car-radius-4 {
    text-align: center;
    padding-left: 30px;
    padding-top: 140px;
    height: 320px;
}
.car-radius-4 {
    margin-top: 16px;
}

.car-radius-1 p,
.car-radius-4 p{
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    font-family: 'FSIndustrieNw-Regular';
    padding-top: 1px;
}
.car-radius-2 p,
.car-radius-3 p{
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    font-family: 'FSIndustrieNw-Regular';
    padding-top: 1px;
    
}
.car-radius-2 p{
    line-height: 20px;
}
.car-radius-1 a,
.car-radius-2 a,
.car-radius-3 a,
.car-radius-4 a {
    text-decoration: none;
}
.car-carrage-img img {
    padding-top: 50px;
    width: 350px;
    
}
.inner-block-car{
    width: 366px;
    height: 140px;
    background: linear-gradient(63.97deg, #CF5734 0.92%, #D86E2D 13.51%, #F2AD19 59.59%, #FAC013 93.01%);
    border-radius: 5px;
    padding: 9px 16px;
    text-align: left;
}
.car-radius-container .default-height{
    height:170px;
}

.inner-block-car2 {
    width: 366px;
    height: 140px;
    border-radius: 5px;
    padding: 9px 16px;
    text-align: left;
    background: linear-gradient(97.57deg, #062137 1.97%, #0F3554 41.76%, #0E4E70 76.41%, #056A8B 100%);
}
.car-carrier-heading p {
   color: #123C60;
   font-size: 20px;
}
.inner-block-car a {
    color:white
}
.inner-block-car2 a {
    color:white
}
.inner-block-car h4{
    font-size: 22px;
    font-weight: 600;
}
.inner-block-car2 h4{
    font-size: 22px;
    font-weight: 600;
}

.gold-flag-img {
    position: absolute;
    top: 38%;
    right: 35%;
}
.car-carrier-section-2-img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50% 0px 0px 50%;
    box-shadow: -13px 0px 10px 0px rgb(0 0 0 / 20%);
}
.car-carrier-section-2-img-box {
    height: 100%;
}
.container.service-box .control-dots,
.news-cont-box .control-dots {
    display: none;
}
.car-carrier-section-2-img-box button.vid-popup {
    height: 100%;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
}
.carrier-yt-cont iframe {
    width: 100%;
    height: 465px;
}
.car-pop-wid {
    max-width: 900px!important;
}
.video-play-button {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-sizing: content-box;
    display: block;
    width: 32px;
    height: 44px;
    border-radius: 50%;
    padding: 18px 20px 18px 28px;
  }
  .video-play-button:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: #FDC200;
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
  }
  .video-play-button:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: #FDC200;
    border-radius: 50%;
    transition: all 200ms;
  }
  .video-play-button-gsl-go {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-sizing: content-box;
    display: block;
    width: 32px;
    height: 44px;
    border-radius: 50%;
    padding: 28px 13px 13px 28px;
  }
  .video-play-button-gsl-go span {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    border-left: 26px solid #fff;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
  }
  .video-play-button-gsl-go:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 60px;
    height: 60px;
    background: #FDC200;
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
  }
  .video-play-button-gsl-go:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 60px;
    height: 60px;
    background: #FDC200;
    border-radius: 50%;
    transition: all 200ms;
  }
  .video-play-button:hover:after {
    background-color: darken(#FDC200, 10%);
  }
  .video-play-button img {
    position: relative;
    z-index: 3;
    max-width: 100%;
    width: auto;
    height: auto;
  }
  .video-play-button span {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    border-left: 32px solid #fff;
      border-top: 22px solid transparent;
      border-bottom: 22px solid transparent;
  }
  .gslgo_register_base .register-form{
    position: relative;
    margin-left: -30px;
    margin-top: 40px;
    padding: 40px !important;
    max-height: 315px;
    border-radius: 4px 0 0 0;
    background: #fff;
    box-shadow: 0 0 15px rgb(0 0 0 / 15%);
    color: #858585;
  }
  .service-cont-1 .service-column-bg {
    background-color: #fff;
    margin:  35px  -5px !important; 
}
.gsl-go-heading {
    text-align: center;
}
.gslgo_register_base .gslgo_btn1 {
    background-color: #FDC200;
    border: 1px solid #FDC200;
    color: #001524;
    font-size: 18px;
    font-weight: 400;
    border-radius: 0;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
}

  @keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
    }
  }

@media only screen and (min-width: 768px) {
    
}
@media only screen and (max-width: 768px) and (min-width: 426px) {
    .footer-bottom-popup-btn {
        bottom: 110px;
    }
    .bottom-fixed-bar-search .fott-bottom {
        display: flex;
        align-items: baseline;
        padding-top: 25px;
        padding-right: 265px;
        justify-content: space-evenly;
        
    }
    .footer-bar-button-box ul li {
        padding: 0px 20px;
    }
  
}
@media only screen and (max-width: 1199px) and (min-width: 769px) {
    .footer-bottom-popup-btn {
        bottom: 93px;
    }
}
@media only screen and (max-width: 768px){
    .container-new.service-box {
        padding-top: 40px;
    }
    .tools-service .tools-heading h2 {
        text-align: center;
        font-size: 36px !important;
        padding-bottom: 0px;
    }
    .tools-service .tools-heading p {
        font-size: 18px;
        text-align: center;
    }
    .gslgo_register_base .register-form {
        position: none !important;
         margin-left: 0px !important;
        margin-top: 40px;
        padding: 20px 15px 20px 15px !important;
        border-radius: 4px 0 0 0;
        background: #fff;
        box-shadow: 0 0 15px rgb(0 0 0 / 15%);
        color: #858585;
        max-height: 500px;
    }
    h2{
        font-size: 28px !important;
    }
    .container-fluid.tools-background {
        background: none;
        background-repeat: no-repeat;
        
    }
    
    .top-info .top-info-right-ul {
        text-align: center;
    }
    input#footer_tracker_box {
        display: none;
    }
    button#tracker_footer_box_button {
        display: none;
    }
    .footer-bottom-popup-btn {
        bottom: 74px;
        right: 38px;
    }
    .bottom-fixed-bar-search {
        height: 75px;  
        border-radius: 50px 50px 0px 0px;
    }
    .footer-bar-button-box ul li a {
        padding: 0;
    }
    .footer-bar-button-box ul li span {
        display: none;
    }
    .s-active:before {
        display: none;
    }
    li.list-inline-item.s-active {
        border-radius: 50px;
    } 
    .gslgo_register_base .service-cont-2 .service-cont-img .service-cont-main-img {
        width: 100%;
        height: 250px;
        object-fit: cover;
    }  
    #gsl_go_page_base .container-fluid.inner-container {
        height: 150px;
        background-size: contain;
    }
    #gsl_go_page_base {
        margin-top: 126px;
    }
    .gsl_go_page_base  {
        margin: 20px 0 !important;
    }
}
@media only screen and (max-width: 768px) {
    
    .vgm-content-box .submit-row-box, .social-reg-box{
        flex-wrap: wrap;
        padding-bottom: 40px;
    }
    .vgm-massupload-box {
        flex-wrap: wrap;
            }
    .solas-vgm-heading h2 {
        text-align: center;
        font-size: 36px !important;
    }
    .submit-vgm-content-text h4, .social-reg-content-text h4, .mass-upload-content-text h4 {
        font-size: 19px;
        line-height: 20px;
    }
    .service-cont-1{
        margin: -10px 0;
    }
    .news-carosouel-cont .carosouel-cont-bg {
        padding: 10px 5px;
    }
    .container-new.sevices-box {
        margin-top: 40px;
        padding: 0px 25px;
    }
    .get-intouch-heading, .services-box-heading {
        text-align: center;
        margin-bottom: 40px!important;
    }
    .services-box-heading h2 {
        font-size: 36px !important;
    }
    .car-carrier-header-2 .videoButton {
        font-size: 18px;
    }
    .car-carrier .car-carrier-section-1{
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .tools-content ul.tool-flex {
        flex-direction: column;
        padding: 0px;
        align-items: center;
    }
    .service-sec-21{
        flex-direction: column;
    }
    .tools-content ul li {
        max-width: 100%;
        padding:0px;
        margin-bottom: 40px;
        min-height: 170px;
    }
    .service-sec-1 .tools-img img {
        width: 50%;
        position: inherit;
        display: none;
    }
    .tools-content .content-text p {
        font-size: 16px;
        color: #123C60;
        line-height: 20px;
        padding: 0px 10px;
    }
    .tools-content ul li {
        margin-bottom: 20px;
    }
    .tools-service .service-sec-21 {
        display: block !important;
        padding: 0px;
        padding-bottom: 0px;
    }
    .solas-vgm .vgm-box-bg {
        padding:40px;
    }
    
    .last-item {
        float: right;
        width: 26px;
        margin: 0px;
    }
    .car-carrier-section-2 .videoButton {
        width: 100%;
    }


    

   
    .ind-sec-content p, .regulation-sec-cont p{
        padding-top: 8px;
        font-size: 12px;
        margin: 0;
        line-height: 18px;
    }
    .car-radius-1 p, .car-radius-4 p {
        font-size: 13px;
    }
    .car-radius-2 p, .car-radius-3 p{
        font-size: 13px;
    }
    .inner-block-car, .inner-block-car2 {
        width: 100%;
        height: 115px;
    }
    .car-radius-2, .car-radius-3, .car-radius-4 {
        padding-right: 30px;
        padding-top: 205px;
        
    }
    .car-radius-1{
        padding-right: 30px;
        padding-top: 150px;
    }

    .text-end {
        text-align: center;
    }
    .gold-flag-img img {
        width: 55px;
    }
    .gold-flag-img {
        top: 37%;
        right: 38%;
    }
    .car-radius-2 img {
        width: 44px;
    }
    .car-carrier-section-2 {
        padding: 0 20px;
        margin-top: 16px;
    }
    .car-carrier-section-2 .videoButton img { 
        padding-left: 10px;

    }
    .car-carrier-section-2 .videoButton{
        font-size: 18px;
    }
    .car-carrier-header-1, .car-carrier-header-2 {
        padding: 0px 20px ;
    }
    .car-carrier-heading p {
        text-align: center;
    }
    .inner-container-section-1 {
       display: block;
       margin-top: 30px;
    }
    .inner-container .inner-container-section-1 h6{
        color:#fff !important;
    }
    .banner-design {
        background-image: none !important;
    }
   
}
a li .content-text h6:hover {
    text-decoration: underline;
}
.tools-service .drop-inner:hover {
   
    text-decoration: none !important;
}

.service-cont-1 .drop-inner:hover{
   
    text-decoration: none !important;
}
