.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{font-family: FSIndustrieNw-Bold, sans-serif !important;}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.our-mission .mission-box {
    padding: 80px 0px;
}
.mission-section-1 img {
    width: 100%;
}
.mission-box .mission-section-2 {
    text-align: left;
}
/* .service-column-bg .service-cont-text {
    text-align: center;
    padding: 36px 10px 0px;
} */

.container-fluid.services-box .services-box-heading{
    margin-top: 60px;
    margin-bottom: 0!important;
}
.mission-box .mission-section-2 h6,
.who-we-are-box .who-we-section-1 h6{
    font-size: 30px;
    color: #000;
    font-weight: bold;
    padding: 20px 0px;
    padding-bottom: 0;
    font-family: 'poppins', sans-serif;
}
.mission-box .mission-section-2 p,
.who-we-are-box .who-we-section-1 p {
    font-size: 16px;
    line-height: 25px;
    color: #666;
    margin-top: 16px;
}
.mission-section-2 button.mission-btn,
.who-we-section-1 button.mission-btn{
    background-color: #123c60!important;
    border: 1px solid #123c60!important;
    border-radius: 0px;
    padding: 8px 25px;
    font-weight: 700;
    font-size: 12px;
    font-family: 'Poppins';
    cursor: pointer;
    outline: none;
    color: #fff;
}
.who-we-are-box .who-we-section-2 {
    padding: 80px 0;
    text-align: center;
}
.who-we-are-box .who-we-section-2 h6 {
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    padding: 20px 0px;
    padding-bottom: 0;
    font-family: 'poppins', sans-serif;
}

.about-us-box .about-us-section-1 h6 {
    font-size: 34px;
    color: #fff;
    font-weight: bold;
    padding: 7px 0px;
    font-family: 'poppins', sans-serif;
}

.who-we-are-box .who-we-section-1 {
    margin-bottom: 80px;
}
.about-us-box .about-us-section-1 p {
    font-size: 14px;
    line-height: 25px;
    color: #fff;
}

.service-cont-1 .service-column-bg {
    background-color: #fff;
    margin: 50px -5px;
}
.abt-service .service-cont-1{
    padding: 80px 15px;
}
.mission-section-2 hr.head-uline-1,
.who-we-section-1 hr.head-uline-1 {

    width: 45px!important;
    margin-left: 0px!important;
    margin-bottom: 0;
}
.container-fluid.who-we-are-box {
    background-repeat: no-repeat;
    background-size: cover;
}
.about-us-box .about-us-section-1 {
    padding: 80px 0;
    
}
.container-fluid.about-us-box {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 300px;
}
section.about-us {
    position: relative;
    margin-top: 105px;
}
.service-column-bg button.service-cont-text-btn {
    margin-bottom: 20px;
}
.inner-container-section-1 {
    text-transform: initial;
    position: absolute;
    top: 3.5vw;
    left: 4vw;
    padding: 0px;
}