.terms-conditions{
	background: #f7f7f7;
    padding: 20px;
    font-size: 13px;
}
.pot-form-ebl{
	box-shadow: 2px 12px 19px #888888;
}
.select2-container--default .select2-selection--single {
    border-radius: 0px !important; 
}
.select2-container .select2-selection--single {
    height: 47px !important;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 38px !important;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 38px !important;
}
.select2-results__option[aria-selected] {
    cursor: pointer;
    font-size: 12px;
}
@media only screen and (max-width: 768px) {
		  .banner-inside {
			height: 100px;
		}
}
.pot-form label .eblable{
	color:#001524 !important;
	font-weight: 600;
}
.checkbox-animated label {
    color: black;
    font-weight: 600;
}

