.bm-menu-wrap {
    display: none;
}
li.dropdown.dropdown-hovers.country-flag-top ul li a {
    font-size: 14px;
    color: #000;
    transition: all .5s ease-out;
    padding: 5px 5px;
    display: block;
}
.top-info .top-info-left-ul {
    margin: 0px !important;
}
.top-info .top-info-right-ul {
    margin: 0px !important;
}
.top-info-left-ul li span i {
    padding-right: 5px;
    font-size: 12px;
    
}
.top-info-left-ul li span {
    padding-left: 5px;
    font-size: 16px;
    font-family: 'FSIndustrieNw-Regular';
}
.top-info-right-ul li a {
    color: #fff;
    padding: 10px;
    text-decoration: none;
    font-family: 'FSIndustrieNw-Regular';

}
.top-info .top-info-left-ul li {
    border-right: 1px solid #fff;
}
.top-info .top-info-left-ul li:nth-last-child(1) {
    border-right: none;
}
.top-info .top-info-right-ul li {
    border-right: 1px solid #fff;
}
.top-info .top-info-right-ul li:nth-last-child(1) {
    border-right: none;
}
.down-arrows-menu img {
    width: 10px !important;
    margin: 0px 5px 0;
}
.login_gsl_img{
    width: 100px !important;
    height: 32px;
    object-fit: contain;
    padding: 2px;
    
}
#navbarNavDropdown {
    justify-content: flex-end;
}
.navbar {
    padding: 0.5rem 0rem;
}
ul.top-info-right-ul .list-inline-item .menu-contact-bg,
.menu-help-bg {
    top: 26px;
    left: -65px;
    width: 190px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 20%);
    border: none;
    border-radius: 0px 0px 10px 10px!important;
	padding: 5px!important;
}
ul.dropdown-menu.menu-contact-bg li a.contact-inner {
    color: #666666;
	padding: 5px 10px;
    font-size: 12px;
}
a.dropdown-toggle.contact-drop-arrow::after {
    margin-left: 6px;
    top: 2px;
    position: relative;
}
.menu-contact-bg li select.con-selet-#ca4d36 {
    width: 58%!important;
    font-size: 13px;
}
.menu-contact-bg li button#header-search-bt {
    width: 80px;
    height: 33px;
    margin-left: 5px;
    font-size: 13px;
}
ul.dropdown-menu.menu-help-bg.knowledge-list {
    left: 45px;
    top: 35px;
}
ul.dropdown-menu.menu-help-bg {
    left: -84px;
}
#flag_login_base_mob{
    display: none;
}


@media only screen and (max-width: 767px){
    #flag_login_base_mob{
        display: inline-block;       
    }
    
    .navbar-light .navbar-toggler-icon {
        background-image: none;
    }
    .navbar-light .navbar-toggler {
       border: none;
       outline: none;
    }
    .header-menu-top {
       background: #fff;
        padding-bottom: 15px;
    }
    .menuClose {
        transform: translate(-100%);
        transition: transform 500ms ease-in-out;
        background: #fff;        
        height: 10px;
    }
    .menuOpen {
        transform: translate(0%);
        transition: transform 500ms ease-in-out;
        background: #fff;
        visibility: visible;
    }
    #homeBannerIndex{
        margin-top:182px;
    }
    #serviceMobileSliderButton{
        display: block !important;
    }
    #clip {
        font-weight: bolder;
        text-align: center;
        margin-bottom: 5px;
        margin-top: 5px;
        padding-bottom: 10px;
        padding-top: 10px;
        font-size: 32px !important;
        
    }
    ul.top-info-right-ul .list-inline-item .menu-contact-bg, .menu-help-bg {
        left: 0;        
    }
    
    .carousel-slider ul.control-dots{
        display: none;
    }
    .head-menu-liss {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    .nav-bar-design .down-arrows-menu {
        display: none;
    }
    .navbar-open.collapse.show ul li {
        padding: 4px 0px;
        z-index: 1;
    }
    a.dropdown-toggle.menu-heads {
        
        padding: 4px 11px;
        text-transform: capitalize;
        font-weight: 400;
        display: block;
    }
    .mmenu1 {
        max-height: 260px !important;
    }
    .mmenu2 {
        max-height: 305px !important;
    }
    .mmenu3 {
        max-height: 300px !important;
    }
    .mmenu5 {
        max-height: 110px !important;
    }
    .mmenu4 {
        max-height: 205px !important;
    }
    .mmenu6 {
        max-height: 210px !important;
    }
    .mmenu7 {
            max-height: 140px !important;
    }
    .gsl_menu1 {
        top: 5px;
        height: 250px !important;
    }
    .gsl_menu2 li {
        padding: 4px 0px;
        z-index: 1;
    }
    .gsl_menu2  {
        height: 310px !important;
    }
    
    .gsl_menu4  {
        height: 205px !important;
    }
    .gsl_menu5  {
        height: 110px !important;
    }
    .gsl_menu6  {
        height: 210px !important;
    }
    .gsl_menu7  {
        height: 100px !important;
    }
    section.background-inner {
        margin-top: 15px;
    }
    .menu-full-pop {
        background: #fff;
        background-image: none !important;
    }
    .menu-full-pop {
        height: 300px;
        background-repeat: no-repeat;
        background-size: 90%;
        background-position: left;
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 28px -6px #191919;
    }
    .gsl_menu2 .head-menu-liss {
        right: 0;
    }
    .mmenu1 .gsl_menu1, .mmenu2 .gsl_menu2, .mmenu3 .gsl_menu3, .mmenu4 .gsl_menu4, .mmenu5 .gsl_menu5, .flagg-drop {
        width: 100% !important;
    }
    .flag_login_base {
        border-left: none !important;
        border-top:none !important;
        text-align: center;
        width: 100%;
        padding:0px;
    }
    ul.dropdown-menu.flagg-drop {
        min-width: auto;
        top: 53px;
        margin-left: 0px !important;
    }
    .tcenter{
        text-align: center;
    }
    .bm-burger-button {
        z-index: 1000;
        position: fixed;
        width: 36px;
        height: 30px;
        right: 30px !important;
        left: auto !important;
        top: 94px !important;
    }
    .bm-burger-button {
        display: none;
    }
    div#navbarNavDropdown {
        display: none;
    }
    .navbar-light .navbar-toggler-icon {
        background: none;
    }
    .header-menu-top {
        background: #fff;
        padding-top: 5px;
       
    }
    .mobMenuLi {
        background: transparent !important;
        border: none;
        padding: 0px;
    }
    .MobMenuUl li {
        padding-top: 8px;
    }
    .MobMenuUl li .mobMenuLia{
        color: #fff;
        display: block;
        text-decoration: none;
        font-size: 16px;
    }
    .MobMenuUl li .dropdown-item {        
        padding: 0.25rem 1.0rem;
    }
    .bm-menu {
        background: #123c60 !important;
    }
    button#dropdown-button-dark-mobile-menu:focus {
        border: none;
        -webkit-highlight: none;
        box-shadow: none;
    }
    .industry-news {
        padding: 15px;
    }
    .industry-news .industry-box {
        padding: 40px 0 !important;
    }
    .car-carrier {
        padding: 15px;
    }
    .industy-heading h2, .regulations-heading h2, .whats-special-heading h2, .get-intouch-heading h2 {
        text-align: center;
    }
    .regulations-heading h2 {
        padding-top: 30px;
    }
    .car-carrier-heading h2, .car-carrier-heading .uline-box-left .head-uline-1, .car-carrier-heading .uline-box-left .head-uline-2{
        text-align: center;
        margin-left: auto;
    }
    .get-intouch .contact-section-2 {
        padding: 0px 30px;
        margin-bottom: 40px;
    }
    .form-fback .pot-form {
        padding-left: 0px !important;
    }
    #point-to-point-new .form-fback {
        padding: 100px 0 0 0;
    }
    .pot-form .start-date-btn, .pot-form .tare-cont-no, .pot-form .track-cont-no {
        align-self: end;
        margin-bottom: 0px;
        padding-top: 15px;
    }
    .get-intouch .container.contact-box {
        padding: 40px 0;
    }
    #shipping_quote .pot-form {
        padding-top: 15px;
        padding-left: 0px;
    }
    #shipping_contact .si-downs a{
        white-space: pre-line;
    }
    #shipping_contact .pot-form {
        padding-top: 15px;
        padding-left: 0px;
    }
    #shipping_contact .si-booking-next {
        text-align: center;
        margin: 30px auto;
        width: 100%;
        display: block !important;
        padding: 0px 15px;
    }
    #shipping_contact .booking-next button {
        margin: 15px 0px;   
    }
   
    .pot-form-ebl {
        box-shadow: 2px 12px 19px #e3e3e3;
    }
    #vgm_form #add_more_bt h4 {
        font-size: 18px;
        padding-top: 10px;
    }
    #vgm_form .remove_more_bt h4 {
        color: #ca4d36;
        font-size: 18px;
        text-align: right;
    }
    #vgm_form .pot-form h4 {
        padding-top: 10px;
    }
    .youtubePopup iframe {
        height: 200px;
    }
    .abt-gsl-img {       
        background-size: contain;
    }
    .n-40 {
        margin: 40px 0 !important;
    }
    .n-career-base {
        padding: 0px;
    }
    .left-time::after, .right-times::after {
        left: 22px;
    }
    .faq-heading{
        padding: 0px;
    }
    .faq-heading h2 {
        font-size: 24px !important;
    }
    .gsl-off-det ul.gsl-off-cont-detail li {
        display: flex;
        font-size: 15px !important;
        padding: 0;
    }
    .bl_terms_conditions h2 {
        font-size: 28px !important;
    }
    .bl_terms_conditions h3 {
        font-size: 18px !important;
    }
    .n-terms_of_use p {
        padding: 15px;
    }
    .n-terms_of_use {
        padding: 0px;
    }
    form#subscribe-form {
        padding: 15px;
    }
    #subscribe-form .pot-form {
        padding-top: 15px;
        padding-left: 0px;
    }
    #subscribe-form .si-booking-next {
        text-align: center;
        margin: 30px auto;
        width: 100%;
        display: block !important;
        padding: 0px 15px;
    }
    #subscribe-form .booking-next button {
        margin: 15px 0px;   
    }
    #subscribe-form .form-fback {
        padding: 0px;
    }
   .temperature_controlled_shipping p {
        word-break: break-word;
    }
    .n-gsl_monitor h1, .n-gsl_monitor h3 {
        font-size: 22px;
    }
    .abt-text-con h3 {
        font-size: 32px;
    }
    ul.factadfi li a {           
        display: inline;
    }
    .carco-track {
        width: 106%;
    }
    .n-accessibility .point-to p {
        padding-left: 15px;
    }
    .n-accessibility {
        padding: 0;
    }
}
@media only screen and (min-width : 768px) {
    .bm-burger-button {
        display: none;
    }
    div#navbarNavDropdown {
        display: none;
    }
    .navbar-light .navbar-toggler-icon {
        background: none;
    }
    .header-menu-top {
        background: #fff;
        
    }
    .mobMenuLi {
        background: transparent !important;
        border: none;
        padding: 0px;
    }
    .MobMenuUl li {
        padding-top: 8px;
    }
    .MobMenuUl li .mobMenuLia{
        color: #fff;
        display: block;
        text-decoration: none;
        font-size: 16px;
    }
    .MobMenuUl li .dropdown-item {        
        padding: 0.25rem 1.0rem;
    }
    .bm-menu {
        background: #123c60 !important;
    }
    button#dropdown-button-dark-mobile-menu:focus {
        border: none;
        -webkit-highlight: none;
        box-shadow: none;
    }
}
@media (max-width: 480px) and (min-width: 320px){
    #homeBannerIndex {
        margin-top: 180px;
    }
    #homeBannerIndex .left-btn {
        display: inline-block;
        position: absolute;
        top: 42%;
        bottom: 0;
        z-index: 9;
        cursor: pointer;
    }
    #homeBannerIndex .right-btn {
        display: inline-block;
        position: absolute;
        top: 42%;
        bottom: 0;
        z-index: 9;
        cursor: pointer;
    }
}
ul.bm-item.list-unstyled.MobMenuUl {
    width: 100% !important;
}
.MobMenuUl .dropdown {
    border-bottom: 1px solid #adadad;
    padding: 10px 20px;
}
.bm-menu-wrap .bm-menu {

    padding: 30px 0px 0px !important;
}
button#dropdown-button-dark-mobile-menu {
    font-weight: 200;
}
li .dropdown .dropdown-menu a.dropdown-item a.drop-inner {
    color: #001524;
    font-weight: 300;
    font-size: 15px;
    width: 100%;
}
li .dropdown .dropdown-menu.show {
    width: 85%;
}
.cursor-pointer{
    cursor: pointer;
}