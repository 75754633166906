.siv-multi-shipment,
.trackshipments_results {
    font-family: 'FSIndustrieNw-Regular', sans-serif;
}

.siv-multi-shipment .tagInfo button,
span.shipmentTagItem button {
    background: none;
    color: #fff;
    box-shadow: unset;
    border: none;
    cursor: pointer;
    font-size: 18px;
    font-family: 'FSIndustrieNw-Regular', sans-serif;
}

.siv-multi-shipment .tagInfo {
    background: #143e68;
    color: #fff;
    padding: 6px 12px;
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;
    border-radius: 6px;
    font-family: 'FSIndustrieNw-Regular', sans-serif;
}

span.shipmentTagItem {
    
    color: #143e68;
    padding: 6px 6px;
    margin-right: 6px;
    margin-bottom: 0px;
    display: inline-block;
    border-radius: 6px;
    font-family: 'FSIndustrieNw-Regular', sans-serif;
}

.siv-multi-shipment .tagsTool {
    border: 2px solid rgb(20 62 104);
    padding: 8px;
    border-radius: 8px;
}

.siv-multi-shipment #containerid {
    width: 70% !important;
    border: 1px solid #e3e3e3;
}

.siv-multi-shipment .inputBox {
    display: flex;
    justify-content: space-between;
}

.siv-multi-shipment .clearAll {
    background: #143e68;
    color: #fff;
    padding: 6px 12px;
    display: flex;
    border-radius: 6px;
    font-family: 'FSIndustrieNw-Regular', sans-serif;
    align-items: center;
    box-shadow: unset;
    border: none;
    cursor: pointer;
}

.track-cont-no {
    align-self: start !important;
    padding-top: 40px;
}

.trackshipments_results {
    margin-left: 30px;
}



.ts-checkbox {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #143e68;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    background-color: white;
}
input.ts-checkbox-p {
    margin: -5px 0px !important;
}
/* Hover effect */
.ts-checkbox:hover {
    border-color: #143e68;
}

/* Checked state with custom SVG checkmark */
.ts-checkbox:checked {
    background-color: #143e68;
    border-color: #143e68;
}

.ts-checkbox:checked::before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 0px;
    width: 16px;
    height: 16px;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="white"><path d="M13.854 3.646a.5.5 0 0 0-.708 0l-6.364 6.364-2.829-2.828a.5.5 0 1 0-.707.707l3.182 3.182a.5.5 0 0 0 .707 0l6.728-6.728a.5.5 0 0 0 0-.707z"/></svg>');
    background-size: 16px 16px;
}

.notifcationBar label {
    display: flex;
    align-items: center;
    margin-right: 5px;
}

.notifcationBar label input {
    margin-right: 5px;
}

a.notificationBtn {
    display: flex;
    align-items: normal;
    font-weight: 600;
    color: #143e68;
}

a.notificationBtn svg {
    margin-left: 5px;
    margin-right: 5px;
}

.trackShipmentResultCard {
    width: 100%;
}

.trackShipmentResultRow {
    display: flex;
    border: 1px solid #E9E9E9;
    padding: 15px 40px;
    border-radius: 6px;
    flex-direction: column;
    margin-bottom: 20px;
    box-shadow: 0 2px 13px #cccccc59;
    position: relative;
}

.trackShipmentResultRow .cartItem {
    margin-right: 5px;
    flex: 1;
    margin-left: 15px;
}



.trackShipmentResultRow .cartItem h3 {
    font-size: 18px;
}

.trackShipmentResultRow .cartItem label {
    margin-bottom: 5px;
}

.trackShipmentResultRow .checkbox {
    margin-top: 5px;
    position: absolute;
    left: 20px;
}

.trackShipmentResultRowInner {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* Two equal columns */
    gap: 10px;

}

.trackShipmentResultRow hr {
    border: 1px dashed #4f4f4f;
    width: 100%;
}

.arrowButton {
    background: #143e68;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    position: absolute;
    top: 15px;
    right: 15px;
}

.notifcationBar {
    margin-left: 15px;
}

.trackshipementFrom {
    display: flex;
    background: #123c60;
    color: #fff;
    padding: 8px;
    border-radius: 8px;
}

.trackshipementVessle {
    display: flex;
    background: rgb(253 194 0 / 20%);
    padding: 8px;
    border-radius: 8px;
}

.trackshipementTransit {
    display: flex;
    background: #E9E9E9;
    padding: 8px;
    border-radius: 8px;
}

.trackshipementTo {
    display: flex;
    background: #FDC200;
    padding: 8px;
    border-radius: 8px;
}

ul.shipmentListpopup {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0px;
    flex-wrap: wrap;
}

#shipmentNofitificationPOPUP .modal-title {
    color: #143e68;
    font-size: 32px;

}

#shipmentNofitificationPOPUP .emailinput {
    background: #EEEEEE;
    border: 1px solid rgb(102 102 102 / 10%);
    padding: 8px 14px;
    border-radius: 5px;
}

.formbuttonSubmit {
    border: 1px saddlebrown;
    background: #fdc200;
    padding: 10px 34px;
    border-radius: 5px;
    font-weight: bold;
    font-family: 'FSIndustrieNw-Bold';
}

.formCancelButton {
    border: 2px solid #143e68;
    background: #fff;
    color: #143e68;
    padding: 10px 34px;
    border-radius: 5px;
    font-weight: bold;
    font-family: 'FSIndustrieNw-Bold';
}

label.popupaccept {
    font-size: 12px;
}

label.popupaccept input {
    margin-right: 5px;
}

label.popupaccept a {
    color: #143e68;
    margin-left: 5px;
    font-weight: bold;
}

.f-24 {
    font-size: 24px;
}

.thanksPageInner {
    background: #F3F5F5;
    display: inline-block;
    padding: 70px;
    border-radius: 15px;
}

.thanksPageInner a.btn.btn-default {
    padding: 7px 24px !important;
}

.thanksPageInner .message,
.thanksPageInner .tick {
    transition: opacity 0.5s ease-in-out;
}

.thanksPageInner .message {
    animation: showMessage 4s infinite;
}

.thanksPageInner .tick {
    animation: showTick 4s infinite;
}

@keyframes showMessage {

    0%,
    50% {
        display: inline-block;
    }

    51%,
    100% {
        display: none;
    }
}

@keyframes showTick {

    0%,
    50% {
        display: none;
    }

    51%,
    100% {
        display: inline-block;
    }
}


.track-shipment .grid-container {
    display: grid;
    grid-template-columns: 1.5fr 2fr 1.5fr 1fr 1.5fr;
    gap: 0px;
    padding: 10px;
    overflow: hidden;
    border-radius: 8px;
    background: white;
    margin-bottom: 15px;
}

.track-shipment .grid-header {
    font-weight: bold;
    padding: 0px;
    font-size: 18px;
    font-family: FSIndustrieNw-Bold, sans-serif;
}

.track-shipment .grid-item {
    padding: 15px;
    font-size: 16px;

}




.track-shipment .final-row {
    font-weight: bold;
    font-family: FSIndustrieNw-Bold, sans-serif;
    color: #001524;
}

/* .track-shipment .grid-item.final-row {
	text-align: right;
  } */

/* Make the first and last items span more space */
.track-shipment .grid-container .grid-item.first-child {

    grid-column: span 2;
    /* First item will take up 2 columns */
}

.track-shipment .grid-container .grid-item.last-child {
    grid-column: span 2;
    /* Last item will take up 2 columns */
}


.font-bold {
    font-family: FSIndustrieNw-Bold, sans-serif;
    font-size: 18px !important;
    font-weight: 600;

}

.pt-0 {
    padding-top: 0;
}

.card.p-3.main-cargo-head-container {
    background-color: #f9f9f9;
    border-color: #123c60;
    min-height: 140px;
    background-color: #fff;
    border: 1px solid #d8e3f2;
    border-radius: 10px;
    box-shadow: 0 4px 20px 0 #00000026;
    display: flex;
    height: auto;
    margin-bottom: 30px;
    min-height: 100px;
}


.track_shipment_inner  .card-grid-container {
    display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 10px;
        padding: 10px;
}

.track_shipment_inner .custom-card-container .accordion button.custom-toggle-link {
    position: absolute;
    right: 0px;
    top: 22px;
    border: none;
    background: none;
}

.track_shipment_inner.custom-card-container .accordion button.custom-toggle-link{
    position: absolute;
    right: 5px;
    top: 22px;
    border: none;
    background: none;
}
#multishippingTabs a.active {
    border-radius: 40px !important;  
}
#multishippingTabs a.nav-link {
    display: inline-block;
    padding: 12px 18px !important;
}

#multishippingTabs .trade-tab-li li a.active {
    border-radius: 40px !important;
}

#multishippingTabs li {
    background: transparent;
}

#multishippingTabs  {
    border: 1px solid #e3e3e3;
    border-radius: 40px;
    display: inline-flex;
    background: #fdc200;
}
#multishippingTabs button#dropdown-basic {
    background: #123c60;
}

#multishippingTabs .dropdown-menu.show {
    background: #fff;
    font-size: 14px;
    border-radius: 5px;
}
#multishippingTabs .dropdown-menu.show a:hover {
    background: #fdc200;
    font-size: 14px;
    border-radius: 5px;
}
#multishippingTabs .dropdown-menu.show a {
    padding: 7px 14px;
}






@media (max-width:767px) {
    .trackShipmentResultRowInner {
        grid-template-rows: auto auto;

        grid-template-columns: repeat(3, 1fr);
        /* Two equal columns */
        gap: 10px;
    }

    .trackShipmentResultRow .cartItem {

        margin-left: 0;
    }

    .trackshipments_results {
        margin-left: 0px;
    }

    .mt-120 {
        margin-top: 160px !important;
    }

    .siv-multi-shipment .tagsTool {
        text-wrap: nowrap;
    }

    .siv-multi-shipment .tagsTool .tags-list {
        display: flex;
        flex-wrap: wrap;
    }

    .thanksPageInner {
        padding: 30px;
    }

    .trackShipmentResultCard {
        width: 100%;
    }

    .track-shipment .grid-container {
        display: grid;
        grid-template-columns: 1fr 200px 100px 100px 200px;
        gap: 0px;
        padding: 10px;
        overflow: scroll;

    }

    .track_shipment_inner  .card-grid-container {
        display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            gap: 10px;
            padding: 10px;
    }

    .track_shipment_inner  .card-grid-container .grid-item:nth-child(1),
    .track_shipment_inner  .card-grid-container .grid-item:nth-child(2)
     {
        grid-column: span 3;
    }

    .track_shipment_inner  .card-grid-container .grid-item:nth-child(3)
     {
        grid-column: span 3;
        /* Second row: three items, one per column */
    }

    .track_shipment_inner  .card-grid-container .grid-item:nth-child(4) {
        grid-column: span 3;
    }


    .track_shipment_inner  .card-grid-container .grid-item:nth-child(5) {
        grid-column: span 3;
        /* Third row: two items, one per column */
    }
    .mobMulitDropdown .dropdown {
        width: 100%;
    }
    button#dropdown-basic-mobile {
        padding: 12px;
        background: #143e68;
        width: 100%;
    }
    .mobMulitDropdown .dropdown-menu {
        width: 100%;
    }
    .mobMulitDropdown a.dropdown-item {
        display: flex;
        justify-content: left;
        align-items: center;
    }
    .mobMulitDropdown .delBtn{
        right: 10px;
    background: #fdc200;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    padding-left: 11px;
    padding-top: 4px;
    font-size: 14px;
    font-weight: bold;
    }
}

@media (max-width:480px) {
    .track-shipment .grid-container {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        
        gap: 0px;

    }

    .track-shipment .grid-item:nth-child(1) {
        grid-column: span 1 / -1;
        /* First row spans all columns */
        display: none;
    }

    .track-shipment .grid-item:nth-child(2),
    .track-shipment .grid-item:nth-child(3) {
        grid-column: span 3;
        /* Second row: three items, one per column */
    }

    .track-shipment .grid-item:nth-child(4),
    .track-shipment .grid-item:nth-child(5) {
        grid-column: span 3;
        /* Third row: two items, one per column */
    }


    .trackShipmentResultRowInner {
        grid-template-rows: auto auto;

        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        /* Two equal columns */
        gap: 10px;
    }

    .trackShipmentResultRowInner .cartItem:nth-child(1) {
        grid-column: span 1 / -1;
        /* First row spans all columns */
        display: none;
       
    }

    .trackShipmentResultRowInner .cartItem:nth-child(2)
     {
        grid-column: span 5;
        /* Second row: three items, one per column */
    }

    .trackShipmentResultRowInner .cartItem:nth-child(3),
    .trackShipmentResultRowInner .cartItem:nth-child(4) {
        grid-column: span 3;
        /* Third row: two items, one per column */
    }
    .trackShipmentResultRowInner .cartItem:nth-child(5),
    .trackShipmentResultRowInner .cartItem:nth-child(6) {
        grid-column: span 3;
        /* Third row: two items, one per column */
    }







    .track-shipment {
        overflow: hidden;
    }

    .track-shipment .grid-item {
        font-size: 14px;
        padding: 4px;
    }

    .trackShipmentResultRow .checkbox {
        margin-top: 3px;
        position: absolute;
        left: 13px;
    }

    .custom-card-container .card-grid-container {
        grid-gap: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        padding: 20px 0px;
    }

    .custom-card-container .card-grid-container .grid-item:nth-child(1),
    .custom-card-container .card-grid-container .grid-item:nth-child(2) {
        grid-column: span 5;
        /* First row spans all columns */

    }


    .custom-card-container .card-grid-container .grid-item:nth-child(3),
    .custom-card-container .card-grid-container .grid-item:nth-child(4) {
        grid-column: span 3;
        /* Second row: three items, one per column */
    }

    .custom-card-container .card-grid-container .grid-item:nth-child(5) {
        grid-column: span 4;
        /* Third row: two items, one per column */
    }





    .custom-card-container .card-grid-container .grid-item .card-tittle {
        margin-bottom: 0px;
    }

    .mob-width {
        width: 1% !important;
    }

    .trackShipmentResultRow {
        display: flex;
        border: 1px solid #E9E9E9;
        padding: 15px 10px;
    }

    .trackShipmentResultRowInnerResult .custom-card-container .accordion .card-body {

        padding: 10px 10px !important;
    }

    .inner-center.mobile-margin {
        margin: 150px 0 !important;
    }

    .no-padd {
        padding: 0 !important;
    }

    .notifcationBar {
        margin-left: 0px;
    }

    .trackShipmentResultRowInner {
        flex-direction: column;
        padding: 0px 30px;

    }


}